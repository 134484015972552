<template>
	<div id="official">
		<div class="screeningCondition">
			<!-- 筛选条件 -->
			<div class="item">
				<el-input clearable v-model="form.meetingTitle" placeholder="搜索会议主题(关键字)"
					@keyup.enter.native="onSubmit"></el-input>
				<!-- 	</div>
			<div class="item"> -->
				<el-input clearable v-model="form.projectName" placeholder="搜索所属项目(关键字)"
					@keyup.enter.native="onSubmit"></el-input>
				<!-- 	</div>
			<div class="item"> -->
				<el-date-picker v-model="form.meetingStartTime" type="month" placeholder="会议时间(月份)"
					value-format="timestamp" @change="onSubmit"></el-date-picker>
				<!-- 	</div>
			<div class="item"> -->
				<el-select v-model="form.meetingStatus" placeholder="会议状态" clearable @change="onSubmit">
					<el-option label="待上传" value="UPLOAD,FINISH,AI_REVIEW_ERROR,OFFICIAL_ERROR,ERROR"></el-option>
					<el-option label="财务审核中" value="OFFICIAL_SUCCESS"></el-option>
					<el-option label="待审核" value="AI_REVIEW_ERROR_ACCEPT,AI_REVIEW_SUCCESS"></el-option>
					<el-option label="已结束" value="SUCCESS"></el-option>
					<el-option label="全部" value=""></el-option>
				</el-select>
				<!-- 	</div>
			<div class="item"> -->
				<el-button type="primary" @click="onSubmit">查询</el-button>
				<el-button @click="reset">重置</el-button>
			</div>
		</div>
		<el-divider></el-divider>
		<el-table :data="meetList" style="width: 100%">
			<el-table-column fixed prop="id" label="会议ID" width="70" align="center"></el-table-column>
			<el-table-column prop="meetingTitle" label="会议主题" min-width="200" align="left"></el-table-column>
			<el-table-column prop="projectName" label="所属项目" min-width="200" align="left"></el-table-column>
			<el-table-column label="会议时间" align="left" min-width="130">
				<template slot-scope="scope">
					{{ $moment(scope.row.meetingStartTime).format('L') }}
					{{ $moment(scope.row.meetingStartTime).format('LT') }}
				</template>
			</el-table-column>
			<el-table-column label="提交时间" align="left" min-width="130">
				<template slot-scope="scope">

					{{ $moment(scope.row.updateTime).format('L') }}
					{{ $moment(scope.row.updateTime).format('LT') }}
				</template>
			</el-table-column>
			<el-table-column label="会议状态" align="center" width="100">
				<template slot-scope="scope">
					<el-tag size="mini" type="success" v-if="scope.row.meetingStatus == 'SUCCESS'">已结束</el-tag>
					<el-tag size="mini" type="primary"
						v-else-if="'AI_REVIEW_ERROR_ACCEPT,AI_REVIEW_SUCCESS'.includes(scope.row.meetingStatus)">待审核</el-tag>
					<el-tag size="mini" type="info" v-else-if="scope.row.meetingStatus == 'ERROR_UPLOAD'">待上传</el-tag>
					<el-tag size="mini" type="primary"
						v-else-if="scope.row.meetingStatus == 'OFFICIAL_SUCCESS'">财务审核中</el-tag>
					<el-tag size="mini" type="info" v-else>{{ dict[scope.row.meetingStatus] }}</el-tag>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="120" align="center">
				<template slot-scope="scope">
					<el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
					<el-button type="text" size="small" @click="goUpload(scope.row)"
						v-if="'UPLOAD,FINISH,ERROR_UPLOAD'.includes(scope.row.meetingStatus)">去上传</el-button>
					<el-button type="text" size="small" @click="goUpload(scope.row)"
						v-if="'AI_REVIEW_ERROR,OFFICIAL_ERROR,ERROR'.includes(scope.row.meetingStatus)">去修改</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination :page-sizes="[10, 20, 50, 100]" @size-change="handleSizeChange" :page-size="page.size"
			:current-page="page.current" @current-change="handleCurrentChange" :hide-on-single-page="false"
			layout="total, sizes, prev, pager, next, jumper" :total="page.total" background>
		</el-pagination>
	</div>
</template>
<script>
	import dict from '@/assets/dictionary/dictionary.js';
	import {
		officialMeetingList,
	} from '@/request/api.js';
	export default {
		name: 'official',
		data() {
			return {
				dict, // 字典
				meetList: [],
				page: {
					current: 1,
					size: 10,
					total: 0
				},
				form: {}
			}
		},
		created() {
			this.getOfficialMeetingList();
		},
		methods: {
			// 去上传文件
			goUpload(row) {
				console.log(row)
				this.$router.push({
					path: '/officialUploadFile',
					query: {
						id: row.id
					}
				})
			},

			onSubmit() {
				console.log(JSON.parse(JSON.stringify(this.form)));
				this.getOfficialMeetingList();
			},

			reset() {
				this.form = {};
				this.onSubmit();
			},

			getOfficialMeetingList() {
				let data = {
					current: this.page.current,
					size: this.page.size
				};
				if (this.form.meetingTitle) data.meetingTitle = this.form.meetingTitle;
				if (this.form.projectName) data.projectName = this.form.projectName;
				if (this.form.meetingStartTime) data.meetingStartTime = this.form.meetingStartTime;
				if (this.form.meetingStatus) data.meetingStatus = this.form.meetingStatus;

				officialMeetingList(data).then(res => {
					if (res.code == 200) {
						this.meetList = res.data.records;
						this.page.total = res.data.total;
					};
				})
			},

			handleSizeChange(val) {
				this.page.size = val;
				this.getOfficialMeetingList();
				// console.log(`每页 ${val} 条`);
			},

			handleCurrentChange(val) {
				this.page.current = val;
				this.getOfficialMeetingList();
				// console.log(`当前页: ${val}`);
			},

			handleClick(row) {
				console.log(row.id);
				this.$router.push({
					path: '/officialMeetingDetail',
					query: {
						meetingId: row.id
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#official {
		padding: 30px;

		.screeningCondition {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.item {
				margin-right: 20px;

				.el-input,
				.el-select,
				.el-date-picker {
					width: 180px;
					margin-right: 10px;
				}
			}
		}

		.el-pagination {
			padding: 30px;
			text-align: center;
		}
	}
</style>