<template>
	<div id="myproject">
		<h1 class="title">我参加的项目</h1>
		<ul class="projectList">
			<li v-for="item in projectList" :key="item.id">
				{{item.projectName}}
				<!-- <i class="el-icon-arrow-right"></i> -->
			</li>
		</ul>
		<el-pagination background layout="prev, pager, next" :total="total" :page-size="size" :current-page.sync="page"
			@current-change="getprojectList" :hide-on-single-page="false"></el-pagination>
	</div>
</template>
<script>
	import {
		dprojectList
	} from '@/request/api.js';
	export default {
		name: 'myproject',
		data() {
			return {
				page: 1,
				size: 10,
				total: 0,
				projectList: []
			}
		},
		created() {
			this.getprojectList();
		},
		mounted() {},
		methods: {
			getprojectList() {
				let data = {
					current: this.page,
					size: this.size
				};
				dprojectList(data).then(res => {
					if (res.code == 200) {
						this.projectList = res.data.records
						this.total = res.data.total
					};
				});
			}
		},
	}
</script>

<style lang="scss" scoped>
	#myproject {
		padding: 40px 20px;

		.title {
			font-size: 20px;
			margin-bottom: 40px;
		}

		.projectList {
			display: flex;
			flex-direction: column;

			li {
				padding: 20px 30px;
				margin-bottom: 20px;
				border-radius: 5px;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 16px;
				color: #333;
			}
		}

		.el-pagination {
			margin: 40px auto 0;
			text-align: center;
		}
	}
</style>