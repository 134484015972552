<template>
	<div id="projectTask" v-loading="loading" :element-loading-text="loadingText">
		<div class="top">
			<div class="title">
				<i></i>
				项目会议配额
			</div>
			<div class="opts">
				<el-button type="primary" size="mini" @click="changeOfficial">新增/修改助教</el-button>
				<el-button type="primary" size="mini" @click="addTask = true">新增配额</el-button>
			</div>
		</div>


		<el-table :data="taskList" style="width: 100%">
			<!-- <el-table-column prop="id" label="标识"> </el-table-column> -->
			<el-table-column prop="actuatorName" label="姓名"> </el-table-column>
			<!-- <el-table-column prop="phoneNumber" label="手机号"> </el-table-column> -->
			<!-- <el-table-column prop="actuatorId" label="执行机构"> </el-table-column> -->
			<el-table-column prop="meetingNum" label="配额数量"> </el-table-column>
			<el-table-column prop="useNum" label="已用配额"> </el-table-column>
			<el-table-column fixed="right" label="操作" align="center" width="100">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="editTaskRow(scope.row),addTask=true"> 修改 </el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
			:page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			:total="total" background>
		</el-pagination>


		<!-- 配额弹窗 -->
		<el-dialog custom-class="addTask" v-if="addTask" :visible.sync="addTask"
			:title="taskRow.type?taskRow.type:'新增配额'" :close-on-click-modal="false">
			<el-form ref="form" :model="taskRow" label-position="top">
				<el-form-item label="选择助教">
					<el-select v-model="taskRow.assistants" multiple placeholder="请选择助教">
						<el-option v-for="item in assistantList" :key="item.id" :label="item.fullName"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="设置配额数量">
					<el-input v-model="taskRow.meetingNum" type="number" min="0" placeholder="请设置配额数量"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addTask=false,taskRow=null">取 消</el-button>
				<el-button type="primary" @click="taskSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ProjectAssistantTaskList,
		projectAssistantUserList,
		addProjectAssistantTask
	} from '@/request/api.js';
	export default {
		name: 'projectTask',
		data() {
			return {
				loading: false,
				loadingText: '',

				taskList: [],
				total: 30,
				pageSize: 10,
				current: 1,
				assistantList: [],

				taskRow: {
					projectId: this.$route.query.pId,
					assistants: '',
					meetingNum: 0
				},

				addTask: false
			}
		},
		created() {
			this.getProjectAssistantTaskList();
			this.getAListOfTeachingAssistants();
		},
		methods: {
			// 新增或修改项目助教
			changeOfficial() {
				this.$router.push({
					name: 'myAssistant',
					query: {
						pId: this.$route.query.pId
					}
				})
			},
			// 确认并提交配额设置
			taskSubmit() {
				let data = []
				if (!this.taskRow.assistants[0]) {
					this.$message.error('请先选择助教')
					return false;
				}
				this.taskRow.assistants.forEach(item => {
					let user = {
						actuatorId: 0,
						projectId: this.taskRow.projectId,
						assistantId: item,
						meetingNum: this.taskRow.meetingNum * 1
					}
					if (!user.meetingNum) {
						this.$message.error('请输入配额数量')
						return false;
					};
					if (this.taskRow.id) {
						user.id = this.taskRow.id
					};
					data.push(user)
				})

				// console.log(data)
				this.loading = true;
				this.loadingText = '加载中...'
				addProjectAssistantTask(data).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.$message.success('操作成功')
						this.addTask = false;
						this.getProjectAssistantTaskList();
					} else {
						this.$message.error(res.msg)
					}
				})
			},

			// 修改配额
			editTaskRow(row) {
				row.type = "修改配额"
				row.assistants = [];
				row.assistants.push(row.assistantId)
				this.taskRow = row;
				this.addTask = true;
			},

			// 获取执行机构助教列表
			getAListOfTeachingAssistants() {
				projectAssistantUserList({
					projectId: this.$route.query.pId
				}).then(res => {
					if (res.code == 200) {
						console.log(res.data)
						this.assistantList = res.data
					};
				});
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getProjectAssistantTaskList();
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.current = val;
				this.getProjectAssistantTaskList();
			},

			// 获取项目当前的配额列表
			getProjectAssistantTaskList() {
				this.loading = true;
				this.loadingText = '加载中...'
				ProjectAssistantTaskList({
					projectId: this.$route.query.pId,
					size: this.pageSize,
					current: this.current
				}).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.taskList = res.data.records
						this.total = res.data.total
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#projectTask {
		padding: 20px 30px;

		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.title {
				font-size: 20px;
				padding: 0 20px 0 30px;
				position: relative;
				margin: 10px 20px 20px 0;

				i {
					top: 50%;
					left: 0px;
					width: 20px;
					height: 20px;
					position: absolute;
					border-radius: 50%;
					background-color: #3563E9;
					border: 5px solid #C2D0F8;
					transform: translateY(-50%);
				}
			}

			.opts {}

		}

		.el-pagination {
			margin: 20px;
			text-align: center;
		}
	}

	::v-deep .addTask {
		width: 400px;
		margin: 0 !important;
		left: 50%;
		top: 50%;
		max-height: 95vh;
		transform: translate(-50%, -50%);

		.el-dialog__body {
			padding: 10px 20px;
		}

		.el-select {
			width: 100%;
		}

		.el-form-item {
			margin: 0 0 10px 0;
		}

		.el-form-item__label {
			padding: 0;
			line-height: 1;
			margin: 10px 0;
		}

		.el-dialog__footer {
			padding-top: 0;
		}
	}
</style>