import request from "./request.js";
import baseUrl from "./config.js"
// import qs from "qs";

// 示例
// export function login(params) {
// 	// let formData = new FormData(); // 创建form对象
// 	// formData.append('presentFormInfoVO', params);
// 	return request({
// 		url: baseUrl + "/spider/web/login",
// 		method: "post",
// 		data: params
// 	});
// 	//其实，也不一定就是params，也可以是 query 还有 data 的呀！
// 	//params是添加到url的请求字符串中的，用于get请求。会将参数加到 url后面。所以，传递的都是字符串。无法传递参数中含有json格式的数据
// 	//而data是添加到请求体（body）中的， 用于post请求。添加到请求体（body）中，json 格式也是可以的。
// }

//获取当前IP
export function getIp() {
	return request({
		url: `https://api.ipify.org/`,
		method: "get",
	});
}

// 助教消息获取
export function getNotice(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getNotice`,
		method: "get",
		params: params
	});
}

// 官员消息获取
export function getOfficialNotice(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getNotice`,
		method: "get",
		params: params
	});
}

// 助教消息详情
export function checkNotice(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/checkNotice`,
		method: "get",
		params: params
	});
}

// 官员消息详情
export function checkNotOfficial(params) {
	return request({
		url: `${baseUrl}/sign/live/official/checkNotice`,
		method: "get",
		params: params
	});
}

// 地图相关请求
export function getMapFun(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getLocation`,
		method: "get",
		params: params
	});
}

// 登录
export function login(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/login`,
		method: "post",
		data: params
	});
}

// 设置初始密码
export function initPassword(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/initPassword`,
		method: "post",
		data: params
	});
}

// 获取项目信息
export function getProjectById(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getProjectById`,
		method: "get",
		params: params
	});
}
export function officialGetProjectById(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getProjectById`,
		method: "get",
		params: params
	});
}

// 删除会议
export function delMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/delMeeting`,
		method: "post",
		data: params
	});
}
export function officialDelMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/official/delMeeting`,
		method: "post",
		data: params
	});
}

// 发送手机验证码
export function sendCode(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/sendCode/${params}`,
		method: "get",
		// params: params
	});
}

// 我的项目 (助教)
export function projectList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/projectList`,
		method: "get",
		params: params
	});
}
// 我的项目 (医生)
export function oprojectList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/projectList`,
		method: "get",
		params: params
	});
}
// 我的项目 (医生)
export function dprojectList(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/projectList`,
		method: "get",
		params: params
	});
}

export function getProjectList(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getProjectList`,
		method: "get",
		params: params
	});
}

// 助教查询医生参与项目列表
export function doctorProjectList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/doctorProjectList`,
		method: "get",
		params: params
	});
}

// 助教获取今日项目
export function todayMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/todayMeeting`,
		method: "get",
		params: params
	});
}
// 官员获取今日项目
export function officialTodayMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/official/todayMeeting`,
		method: "get",
		params: params
	});
}

// 助教获取进入会议的链接
export function getMeetingUrl(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getMeetingUrl`,
		method: "get",
		params: params
	});
}
// 官员获取进入会议的链接
export function ogetMeetingUrl(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getMeetingUrl`,
		method: "get",
		params: params
	});
}

// 新注册用户审核
export function examineUser(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/examineUser`,
		method: "post",
		data: params
	});
}

// 查询会后待上传数量
export function uploadMeetingNum(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getUploadMeetingNum`,
		method: "get",
		params: params
	});
}
export function officialMeetingNum(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getUploadMeetingNum`,
		method: "get",
		params: params
	});
}

// 获取会议列表
export function meetList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/meetingList`,
		method: "get",
		params: params
	});
}
export function officialMeetList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/meetingList`,
		method: "get",
		params: params
	});
}
export function orderMeetingList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/orderMeetingList`,
		method: "get",
		params: params
	});
}

// 保存会后文件
export function saveMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/saveMeetingFile`,
		method: "post",
		data: params
	});
}

// 上传会后文件  
export function uploadMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/uploadMeetingFile`,
		method: "post",
		data: params
	});
}

// 官员保存会后文件  
export function officialSaveMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/official/saveMeetingFile`,
		method: "post",
		data: params
	});
}
// 官员上传会后文件  
export function officialUploadMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/official/uploadMeetingFile`,
		method: "post",
		data: params
	});
}

// 获取待审核医生列表   waitDoctors
export function waitDoctors(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/waitDoctors`,
		method: "get",
		params: params
	});
}

// 删除会后上传文件  
export function delMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/delMeetingFile`,
		method: "get",
		params: params
	});
}

// 官员删除会后上传文件  
export function officialDelMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/official/delMeetingFile`,
		method: "get",
		params: params
	});
}

// 助教会议上传资料回显
export function doctorGetMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getMeetingFile`,
		method: "get",
		params: params
	});
}

// 助教会议上传资料回显
export function getMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getMeetingFile`,
		method: "get",
		params: params
	});
}

// 官员会议上传资料回显
export function officialMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getMeetingFile`,
		method: "get",
		params: params
	});
}

// 医生端 获取会议列表 
export function doctorMeetList(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/meetingList`,
		method: "get",
		params: params
	});
}

// 医生端 今日会议  
export function doctorTodayMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/todayMeeting`,
		method: "get",
		params: params
	});
}

// 获取待签署协议
export function getAgreementNum(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getAgreementNum`,
		method: "get",
		params: params
	});
}

// 进会前签到   
export function signIn(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/signIn`,
		method: "post",
		data: params
	});
}

// 手动结束会议
export function meetingOver(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/meetingOver`,
		method: "post",
		data: params
	});
}
export function officialMeetingOver(params) {
	return request({
		url: `${baseUrl}/sign/live/official/meetingOver`,
		method: "post",
		data: params
	});
}

// 医生获取会议链接
export function doctorGetMeetingUrl(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getMeetingUrl`,
		method: "get",
		params: params
	});
}

// 医生获取会议详情 
export function getMeetDetil(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getUpdateMeetingById`,
		method: "get",
		params: params
	});
}

// 助教获取会议详情 
export function getUpdateMeetInfo(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getUpdateMeetingById`,
		method: "get",
		params: params
	});
}
export function officialGetUpdateMeetInfo(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getUpdateMeetingById`,
		method: "get",
		params: params
	});
}

// 获取执行机构列表
export function getOrgList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/actuatorList`,
		method: "get",
		params: params
	});
}

export function officialActuatorList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/actuatorList`,
		method: "get",
		params: params
	});
}

// 更新会议进入
export function updateMeetingStatus(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/updateMeetingStatus`,
		method: "post",
		data: params
	});
}

// 立项单位列表
export function getMechanismList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/mechanismList`,
		method: "get",
		params: params
	});
}
export function officialtMechanismList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/mechanismList`,
		method: "get",
		params: params
	});
}

//支持企业列表
export function getSupportList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/supportList`,
		method: "get",
		params: params
	});
}

// 查询部门内人员列表
export function getAssistantUserList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/assistantUserList`,
		method: "get",
		params: params
	});
}
export function getofficialUserList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/assistantUserList`,
		method: "get",
		params: params
	});
}
// 通过项目ID查询助教
export function projectAssistantUserList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/projectAssistantUserList`,
		method: "get",
		params: params
	});
}
// 助教获取会议详情 
export function getEnterPriseList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/enterPriseList`,
		method: "get",
		params: params
	});
}
export function officialEnterPriseList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/enterPriseList`,
		method: "get",
		params: params
	});
}

// 医生查询项目角色列表
export function roleList(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getMeetingRoleList`,
		method: "get",
		params: params
	});
}

// 官员查询项目角色列表
export function officialRoleList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getMeetingRoleList`,
		method: "get",
		params: params
	});
}

// 助教查询项目角色列表 /sign/live/assistant/getProjectRoleList
export function getroleList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getProjectRoleList`,
		method: "get",
		params: params
	});
}

export function getMeetRoleList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getMeetingRoleList`,
		method: "get",
		params: params
	});
}
export function officialGetMeetRoleList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getMeetingRoleList`,
		method: "get",
		params: params
	});
}

// 获取会议详情
export function meetingMessage(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getMeetingMessage`,
		method: "get",
		params: params
	});
}

// 医生签署协议/sign/live/doctor/startSign
export function startSign(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/startSign`,
		method: "post",
		data: params
	});
}

//获取用户信息列表
export function meetingUserInfoList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/meetingUserInfoList`,
		method: "get",
		params: params
	});
}

// 查询用户上传的文件
export function getUserFileList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/userFileList`,
		method: "get",
		params: params
	});
}

// 医生调取劳务列表 
export function orderList(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/orderList`,
		method: "get",
		params: params
	});
}
export function officialOrderList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/orderList`,
		method: "get",
		params: params
	});
}

// 助教调取劳务列表 
export function orderListAssistant(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/orderList`,
		method: "get",
		params: params
	});
}

// 官员会议列表
export function officialMeetingList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getOfficialMeetingList`,
		method: "get",
		params: params
	});
}

// 官员会议详情
export function officialMeetingDetail(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getOfficialMeetingDetail`,
		method: "get",
		params: params
	});
}

//查看会议协议签署情况
export function getAgreementList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/agreementList`,
		method: "get",
		params: params
	});
}

// 官员查看会议协议签署情况
export function officialAgreementList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/agreementList`,
		method: "get",
		params: params
	});
}

// 获取医生用户信息 
export function getDoctorInfo(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getUserInfo`,
		method: "get",
		params: params
	});
}

// 获取官员用户信息 
export function getOfficialInfo(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getUserInfo`,
		method: "get",
		params: params
	});
}

// 获取助教用户信息 
export function getAssistantInfo(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getUserInfo`,
		method: "get",
		params: params
	});
}

// 助教获取立项单位公告
export function assistantGetMechanismNotice(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getMechanismNotice`,
		method: "get",
		params: params
	});
}

// 官员获取立项单位公告
export function officialGetMechanismNotice(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getMechanismNotice`,
		method: "get",
		params: params
	});
}

// 医生参与的项目数量和会议数量 
export function getMyProjectNumAndMeetingNum(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/getMyProjectNumAndMeetingNum`,
		method: "get",
		params: params
	});
}

export function getMeetingNum(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getMyProjectNumAndMeetingNum`,
		method: "get",
		params: params
	});
}

// 注册医生用户
export function register(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/register`,
		method: "post",
		data: params
	});
}

// 修改密码 
export function changePassword(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/changePassword`,
		method: "post",
		data: params
	});
}
// /sign/live/assistant/changePassword
export function changeAssistantPwd(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/changePassword`,
		method: "post",
		data: params
	});
}

// 注册用户信息
export function information(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/information`,
		method: "post",
		data: params
	});
}

// 助教修改医生信息
export function changeInfobyId(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/information`,
		method: "post",
		data: params
	});
}

// 获取医生列表  
export function doctorList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getDoctorList`,
		method: "get",
		params: params
	});
}
export function officialGetDoctorList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getDoctorList`,
		method: "get",
		params: params
	});
}

// 根据Id获取医生信息
export function getDoctorById(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getDoctorById`,
		method: "get",
		params: params
	});
}

// 获取签署协议模板列表
export function getTemplate(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getTemplate`,
		method: "get",
		params: params
	});
}

// 发送签属协议的短信  
export function sendText(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/sendText`,
		method: "post",
		data: params
	});
}
export function officialSendText(params) {
	return request({
		url: `${baseUrl}/sign/live/official/sendText`,
		method: "post",
		data: params
	});
}

// 新建会议  
export function createMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/createMeeting`,
		method: "post",
		data: params
	});
}
export function officialCreateMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/official/createMeeting`,
		method: "post",
		data: params
	});
}

// 修改会议  
export function updateMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/updateMeeting`,
		method: "post",
		data: params
	});
}
export function officialUpdateMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/official/updateMeeting`,
		method: "post",
		data: params
	});
}

// 上传会议KV与海报  修改会议海报及背景图 
export function updateMeetingImage(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/updateMeetingImage`,
		method: "post",
		data: params
	});
}

// 回显会议详情
export function getMeetingById(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getMeetingById`,
		method: "get",
		params: params
	});
}

// 重新发起审核
export function reapply(params) {
	return request({
		url: `${baseUrl}/sign/live/doctor/reapply`,
		method: "post",
		data: params
	});
}

// 导入项目用户 
export function importProjectUser(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/importProjectUser`,
		method: "post",
		data: params
	});
}

// 官员审核会议材料 确认无误后提交财务审核
export function examineOfficialMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/official/examineOfficialMeeting`,
		method: "post",
		data: params
	});
}

// 导入明细信息
export function getSheetData(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getSheetData`,
		method: "post",
		data: params
	});
}

//获取明细内文件夹内文件列表
export function getProjectConfig(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getProjectConfigByType`,
		method: "GET",
		params: params
	});
}

// 保存或修改结算单
export function saveUpdateOrder(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/saveOrUpdateOrder`,
		method: "post",
		data: params
	});
}
export function saveOrder(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/saveOrder`,
		method: "post",
		data: params
	});
}

// 获取结算单信息 通过会议ID
export function getOrderByMeetId(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getOrderByMeetingId`,
		method: "get",
		params: params
	});
}
export function getOrderById(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getOrderById`,
		method: "get",
		params: params
	});
}

//会议收款方式
export function getProjectCollectWay(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getProjectCollectWay`,
		method: "get",
		params: params
	});
}
export function getCollectWay(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getCollectWay`,
		method: "get",
		params: params
	});
}

// 获取明细内文件夹列表
export function getDetailFolders(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getDetailFolders`,
		method: "get",
		params: params
	});
}

// 获取指定类型下的明细类型
export function getFolderById(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getFolderById`,
		method: "get",
		params: params
	});
}

// 提交明细内文件夹内文件列表
export function submitFiles(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/submitDetailFolderFiles`,
		method: "post",
		data: params
	});
}

// 获取明细内文件夹内文件列表
export function getFiles(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getDetailFolderAndFiles`,
		method: "get",
		params: params
	});
}

// 新增或修改明细内文件夹
export function saveOrUpdateFolder(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/saveOrUpdateDetailFolder`,
		method: "post",
		data: params
	});
}

// 新增或修改明细内文件夹
export function delFolderAndFiles(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/delFolderAndFiles`,
		method: "post",
		data: params
	});
}


// 下载文件
export function downloadFile(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/downloadFolder`,
		method: "get",
		params: params,
		responseType: 'blob',
		timeout: 100 * 1000
	});
}

// 确认协议 
export function editOrderView(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/editOrderView`,
		method: "post",
		params: params
	});
}

// 删除明细
export function delDetail(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/delDetail`,
		method: "post",
		params: params
	});
}

// 提交结算单
export function submitOrder(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/submitOrder`,
		method: "post",
		params: params
	});
}
export function uploadOrder(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/uploadOrder`,
		method: "post",
		data: params
	});
}

// 获取回款记录明细
export function getOrdersByProjectId(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getOrdersByProjectId`,
		method: "get",
		params: params
	});
}

// 更新会议文件
export function updateMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/updateMeetingFile`,
		method: "post",
		data: params
	});
}
export function officialUpdateMeetingFile(params) {
	return request({
		url: `${baseUrl}/sign/live/official/updateMeetingFile`,
		method: "post",
		data: params
	});
}


// 获取会议审批流
export function approvalProcess(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getApprovalProcess`,
		method: "get",
		params: params
	});
}
export function officialApprovalProcess(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getApprovalProcess`,
		method: "get",
		params: params
	});
}

// 供应商结算 金额变化自动保存
export function editFolderMoney(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/editFolderMoney`,
		method: "post",
		data: params
	});
}

// 助教获取劳务明细
export function meetingOrderList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/meetingOrderList`,
		method: "get",
		params: params
	});
}

// 下载劳务明细
export function exportYzfOrder(params) {
	return request({
		url: `${baseUrl}/sign/live/official/exportYzfOrder`,
		method: "get",
		params: params,
		responseType: 'blob',
		timeout: 100 * 1000
	});
}

// 删除视频
export function delMeetingVideo(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/delMeetingVideo`,
		method: "post",
		data: params
	});
}

export function delOfficialMeetingVideo(params) {
	return request({
		url: `${baseUrl}/sign/live/official/delMeetingVideo`,
		method: "post",
		data: params
	});
}

// 官员确认批量创建会议
export function saveProjectMeeting(params) {
	return request({
		url: `${baseUrl}/sign/live/official/saveProjectMeeting`,
		method: "post",
		data: params
	});
}

// 官员获取项目配额列表
export function ProjectAssistantTaskList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getProjectAssistantTaskList`,
		method: "get",
		params: params
	});
}
// 为助教添加系列会建会配额
export function addProjectAssistantTask(params) {
	return request({
		url: `${baseUrl}/sign/live/official/addProjectAssistantTask`,
		method: "post",
		data: params
	});
}

// 保存助教信息
export function saveProjectAssistant(params) {
	return request({
		url: `${baseUrl}/sign/live/official/saveProjectAssistant`,
		method: "post",
		data: params
	});
}

// 修改用户信息
export function updateProjectUser(params) {
	return request({
		url: `${baseUrl}/sign/live/official/updateProjectUser`,
		method: "post",
		data: params
	});
}

// 助教认领项目
export function claimProject(params) {
	return request({
		url: `${baseUrl}/sign/live/official/claimProject`,
		method: "post",
		data: params
	});
}

// 助教获取地区列表
export function getLocationListass(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getLocationList`,
		method: "get",
		data: params
	});
}

// 官员获取地区列表
export function getLocationList(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getLocationList`,
		method: "get",
		data: params
	});
}


// 获取常用模板
export function getTemplateList(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getTemplateList`,
		method: "get",
		data: params
	});
}

// 助教获取公告
export function getProjectNotice(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getProjectNotice`,
		method: "get",
		params: params
	});
}
// 官员获取公告
export function officialGetProjectNotice(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getProjectNotice`,
		method: "get",
		params: params
	});
}

// 助教确认公告内容
export function acceptProjectNotice(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/acceptProjectNotice`,
		method: "post",
		data: params
	});
}

// 官员确认公告内容
export function officialacceptProjectNotice(params) {
	return request({
		url: `${baseUrl}/sign/live/official/acceptProjectNotice`,
		method: "post",
		data: params
	});
}

// 设置修改公告
export function saveOrUpdateProjectNotice(params) {
	return request({
		url: `${baseUrl}/sign/live/official/saveOrUpdateProjectNotice`,
		method: "post",
		data: params
	});
}

// 官员下载所有的劳务协议
export function officialdownloadAgreement(params) {
	return request({
		url: `${baseUrl}/sign/live/official/downloadAgreement`,
		method: "get",
		params: params,
		responseType: 'blob',
		timeout: 100 * 1000
	});
}

// 官员下载所有的劳务协议
export function officialddownloadAllFile(params) {
	return request({
		url: `${baseUrl}/sign/live/official/downloadAll`,
		method: "get",
		params: params,
		responseType: 'blob',
		timeout: 100 * 1000
	});
}

// 助教下载劳务协议
export function downloadAgreementAss(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/downloadAgreement`,
		method: "get",
		params: params,
		responseType: 'blob',
		timeout: 100 * 1000
	});
}

// 获取当前账号关联的立项单位
export function getMyMechanism(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/getMyMechanism`,
		method: "get",
		params: params
	});
}

// 修改当前的立项单位
export function editMechanism(params) {
	return request({
		url: `${baseUrl}/sign/live/assistant/editMechanism`,
		method: "post",
		data: params
	});
}

// 官员获取当前账号关联的立项单位
export function officialGetMyMechanism(params) {
	return request({
		url: `${baseUrl}/sign/live/official/getMyMechanism`,
		method: "get",
		params: params
	});
}

// 官员修改当前的立项单位
export function officialEditMechanism(params) {
	return request({
		url: `${baseUrl}/sign/live/official/editMechanism`,
		method: "post",
		data: params
	});
}