import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import moment from 'moment';
moment.locale('zh-cn');
Vue.prototype.$moment = moment;

// import VueAMap from 'vue-amap';
// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
// 	key: 'cbcb7c74bcbeb6042114aac4a0645fcd',
// 	plugin: [
// 		'AMap.Autocomplete',
// 		'AMap.PlaceSearch',
// 		'AMap.Scale',
// 		'AMap.OverView',
// 		'AMap.ToolBar',
// 		'AMap.MapType',
// 		'AMap.PolyEditor',
// 		'AMap.CircleEditor',
// 		'AMap.Geolocation'
// 	],
// 	v: '1.4.4'
// });

// import BaiduMap from 'vue-baidu-map'
// Vue.use(BaiduMap, {
// 	// ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
// 	ak: '5n8tAI8EUkYg4WYfBs2TVn60Qpqmxza3'
// })

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')