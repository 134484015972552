import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
		path: '/',
		component: () => import('../views/Home.vue'),
		children: [{
			path: '',
			name: 'meetlist',
			component: () => import('../views/d_meetlist/meetlist.vue'),
			meta: {
				title: '预定会议'
			}
		}, {
			path: '/official',
			name: 'official',
			component: () => import('@/views/type_official/official.vue'),
			meta: {
				title: '会议列表'
			}
		}, {

			path: '/officialMeetingDetail',
			name: 'officialMeetingDetail',
			component: () => import('@/views/type_official/meetingDetail/meetingDetail.vue'),
			meta: {
				title: '会议详情'
			}
		}, {
			path: 'meethistory',
			name: 'meethistory',
			component: () => import('../views/d_meetlist/meethistory.vue'),
			meta: {
				title: '历史会议'
			}
		}, {
			path: 'dmeetdetail',
			name: 'dmeetdetail',
			component: () => import('../views/d_meetdetail/meetdetail.vue'),
			meta: {
				title: '会议详情'
			}
		}, {
			path: 'livePage/:id?',
			name: 'livePage',
			component: () => import('../views/livePage/livePage.vue'),
			meta: {
				title: '直播页面'
			}
		}, {
			path: 'laowu',
			name: 'laowu',
			component: () => import('../views/d_laowu/laowu.vue'),
			meta: {
				title: '劳务'
			}
		}, {
			path: 'patientInfo/:id?',
			name: 'patientInfo',
			component: () => import('../views/patientInfo/patientInfo.vue'),
			meta: {
				title: '劳务详情'
			}
		}, {
			path: 'agreement',
			name: 'agreement',
			component: () => import('../views/agreement/agreement.vue'),
			meta: {
				title: '签署劳务协议'
			}
		}, {
			path: 'my',
			name: 'my',
			component: () => import('../views/my/my.vue'),
			meta: {
				title: '我的'
			}
		}, {
			path: 'changeInfo',
			name: 'changeInfo',
			component: () => import('../views/changeInfo/changeInfo.vue'),
			meta: {
				title: '修改个人信息'
			}
		}, {
			path: 'changepwd',
			name: 'changepwd',
			component: () => import('../views/changepwd/changepwd.vue'),
			meta: {
				title: '修改密码'
			}
		}, {
			path: 'singleMeets',
			name: 'singleMeets',
			component: () => import('../views/type_assistant/z_meetlist/singleMeets.vue'),
			meta: {
				title: '单场会管理'
			}
		}, {
			path: 'project',
			name: 'project',
			component: () => import('../views/type_assistant/z_project/project.vue'),
			meta: {
				title: '系列会管理'
			}
		}, {
			path: 'officialSingleMeets',
			name: 'officialSingleMeets',
			component: () => import('../views/type_official/o_meetlist/officialSingleMeets.vue'),
			meta: {
				title: '单场会管理'
			}
		}, {
			path: 'offocoalProject',
			name: 'offocoalProject',
			component: () => import('../views/type_official/o_project/project.vue'),
			meta: {
				title: '系列会管理'
			}
		}, {
			path: 'meetinglist',
			name: 'meetinglist',
			component: () => import('../views/type_assistant/z_meetlist/meetlist.vue'),
			meta: {
				title: '会议列表'
			}
		}, {
			path: 'officialMeetinglist',
			name: 'officialMeetinglist',
			component: () => import('../views/type_official/o_meetlist/meetlist.vue'),
			meta: {
				title: '会议列表'
			}
		}, {
			path: 'meetdetails',
			name: 'meetdetails',
			component: () => import('../views/type_assistant/z_meetdetails/meetdetails.vue'),
			meta: {
				title: '会议详情'
			}
		}, {
			path: 'myproject',
			name: 'myproject',
			component: () => import('../views/d_myproject/myproject.vue'),
			meta: {
				title: '我的项目'
			}
		}, {
			path: 'projectNotice',
			name: 'projectNotice',
			component: () => import('../views/type_official/projectNotice/index.vue'),
			meta: {
				title: '项目公告'
			}
		}, {
			path: 'createmeet',
			name: 'createmeet',
			component: () => import('../views/type_assistant/z_createmeet/createmeet.vue'),
			meta: {
				title: '创建会议'
			}
		}, {
			path: 'officialCreatemeet',
			name: 'officialCreatemeet',
			component: () => import('../views/type_official/o_createmeet/createmeet.vue'),
			meta: {
				title: '创建会议'
			}
		}, {
			path: 'template',
			name: 'template',
			component: () => import('../views/type_assistant/z_template/index.vue'),
			meta: {
				title: '常用模板'
			}
		}, {
			path: 'batchCreateMeet',
			name: 'batchCreateMeet',
			component: () => import('../views/type_official/o_createmeet/batchCreateMeet.vue'),
			meta: {
				title: '批量创建会议'
			}
		}, {
			path: 'batchCreateAssistant',
			name: 'batchCreateAssistant',
			component: () => import('../views/type_official/myAssistant/batchCreateAssistant.vue'),
			meta: {
				title: '批量创建助教'
			}
		}, {
			path: 'notifications',
			name: 'notifications',
			component: () => import('../views/type_assistant/z_notifications/notifications.vue'),
			meta: {
				title: '会议操作'
			}
		}, {
			path: 'mapTest',
			name: 'mapTest',
			component: () => import('../views/type_assistant/mapTest.vue'),
			meta: {
				title: '地图测试'
			}
		}, {
			path: 'liveUrl',
			name: 'liveUrl',
			component: () => import('../views/type_assistant/z_liveurl/liveUrl.vue'),
			meta: {
				title: '预览会议链接与二维码'
			}
		}, {
			path: 'coverupload',
			name: 'coverupload',
			component: () => import('../views/type_assistant/z_coverupload/coverupload.vue'),
			meta: {
				title: '上传KV海报'
			}
		}, {
			path: 'confmeet',
			name: 'confmeet',
			component: () => import('../views/type_assistant/z_confmeet/confmeet.vue'),
			meta: {
				title: '确认会议信息'
			}
		}, {
			path: 'doctor',
			name: 'doctor',
			component: () => import('../views/type_assistant/z_doctor/doctor.vue'),
			meta: {
				title: '我的医生'
			}
		}, {
			path: 'doctorinfo',
			name: 'doctorinfo',
			component: () => import('../views/type_assistant/z_doctorinfo/doctorinfo.vue'),
			meta: {
				title: '医生详情'
			}
		}, {
			path: 'reviewdoctor',
			name: 'reviewdoctor',
			component: () => import('../views/type_assistant/z_doctor/reviewdoctor.vue'),
			meta: {
				title: '待审核医生'
			}
		}, {
			path: 'creatdoc',
			name: 'creatdoc',
			component: () => import('../views/type_assistant/z_creatdoc/creatdoc.vue'),
			meta: {
				title: '创建账号'
			}
		}, {
			path: 'perInfo',
			name: 'perInfo',
			component: () => import('../views/type_assistant/z_creatdoc/perInfo.vue'),
			meta: {
				title: '创建账号'
			}
		}, {
			path: 'upInfo',
			name: 'upInfo',
			component: () => import('../views/type_assistant/z_creatdoc/upInfo.vue'),
			meta: {
				title: '创建账号'
			}
		}, {
			path: 'selectPro',
			name: 'selectPro',
			component: () => import('../views/type_assistant/z_creatdoc/selectPro.vue'),
			meta: {
				title: '创建账号'
			}
		}, {
			path: 'option',
			name: 'option',
			component: () => import('../views/type_assistant/z_option/option.vue'),
			meta: {
				title: '操作'
			}
		}, {
			path: 'supplierSettlement',
			name: 'supplierSettlement',
			component: () => import('../views/type_official/supplierSettlement/index.vue'),
			meta: {
				title: '供应商结算'
			}
		}, {
			path: 'settleaccount',
			name: 'settleaccount',
			component: () => import('../views/type_official/settleaccount/settleaccount.vue'),
			meta: {
				title: '项目回款记录'
			}
		}, {
			path: 'fileUpdataReview',
			name: 'fileUpdataReview',
			component: () => import('../views/type_official/fileUpdataReview/index.vue'),
			meta: {
				title: '供应商结算'
			}
		}, {
			path: 'fileListPreview',
			name: 'fileListPreview',
			component: () => import('../views/type_official/fileListPreview/index.vue'),
			meta: {
				title: '明细文件'
			}
		}, {
			path: 'officialUploadFile',
			name: 'officialUploadFile',
			component: () => import('../views/type_official/officialUploadFile/index.vue'),
			meta: {
				title: '上传文件'
			}
		}, {
			path: 'myAssistant',
			name: 'myAssistant',
			component: () => import('../views/type_official/myAssistant/index.vue'),
			meta: {
				title: '我的助教'
			}
		}, {
			path: 'projectTask',
			name: 'projectTask',
			component: () => import('../views/type_official/projectTask/index.vue'),
			meta: {
				title: '项目配额'
			}
		}, {
			path: 'uploadFile',
			name: 'uploadFile',
			component: () => import('../views/type_assistant/z_upLoadFile/index.vue'),
			meta: {
				title: '上传文件'
			}
		}]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/login.vue'),
		meta: {
			title: '登录'
		}
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('@/views/register/register.vue'),
		meta: {
			title: '注册'
		}
	},
	{
		path: '/ImproveInfo',
		name: 'ImproveInfo',
		component: () => import('@/views/register/ImproveInfo.vue'),
		meta: {
			title: '完善信息'
		}
	},
	{
		path: '/upLoadInfo',
		name: 'upLoadInfo',
		component: () => import('../views/register/UploadInfo.vue'),
		meta: {
			title: '上传照片'
		}
	},
	{
		path: '/setpwd',
		name: 'setpwd',
		component: () => import('@/views/register/setpwd.vue'),
		meta: {
			title: '设置密码'
		}
	},
	{
		path: '/SelectProject',
		name: 'SelectProject',
		component: () => import('@/views/register/SelectProject.vue'),
		meta: {
			title: '选择参与项目'
		}
	},
	{
		path: '/result',
		name: 'result',
		component: () => import('../views/register/result.vue'),
		meta: {
			title: ''
		}
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import('../components/NotFound.vue'),
		meta: {
			title: '404'
		}
	},

];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
	// 让页面回到顶部
	document.documentElement.scrollTop = 0
	// 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
	if (to.name != 'nopath') {
		if (to.meta.title) {
			document.title = `万木智会-${to.meta.title}`;
		} else {
			document.title = `万木智会`;
		};
		next();
	}
});

export default router