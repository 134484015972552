<template>
	<div id="huizhen">
		<div class="search">
			<el-input placeholder="请输入会议名称" v-model="searchVal" clearable> </el-input>
			<el-date-picker v-model="searchVal2" type="month" placeholder="选择月" value-format="timestamp">
			</el-date-picker>
			<el-button type="primary" @click="search">搜索</el-button>
		</div>
		<div class="hzCont">
			<div class="list">
				<h1 v-if="todayMeet[0]">今日会议</h1>
				<el-carousel height="350px" v-if="todayMeet[0]" indicator-position="outside" :autoplay="false">
					<el-carousel-item v-for="item in todayMeet" :key="item.id">
						<div class="bgImg">
							<img v-if="item.bill&&item.bill!='null'" :src="item.bgImage">
							<img v-else src="../../assets/imgs/back2.png">
							<span></span>
						</div>
						<div class="main">
							<div class="tit">
								会议主题：
								{{item.meetingTitle}}
							</div>
							<div class="pro">
								<img src="../../assets/imgs/icon1.png">
								所属项目：
								{{item.projectName}}
							</div>
							<div class="time">
								<img src="../../assets/imgs/icon2.png">
								开始时间：
								{{$moment(item.meetingStartTime).format('L') }}
								{{$moment(item.meetingStartTime).format('LT') }}
							</div>
							<div class="users">
								<img src="../../assets/imgs/icon3.png">
								参会医生：
								{{item.doctors}}
							</div>
							<el-button class="goLive" @click="enterTheMeeting(item)">进入会议</el-button>
						</div>
					</el-carousel-item>
				</el-carousel>
				<h1>近期会议</h1>
				<div class="item" v-for="(item,i) in meetingList" :key="i" @click="toHuiyiDetails(item)">
					<div class="tags">
						<div class="type" v-if="item.meetingType=='series'">
							<img :src="require('@/assets/imgs/video.png')">系列会
						</div>
						<div class="type" v-if="item.meetingType=='single'">
							<img :src="require('../../assets/imgs/video2.png')">单场会
						</div>
						<div class="idNum">编号：{{item.id}}</div>
					</div>
					<div class="main">
						<div class="left">
							<div class="tit">主题：{{item.meetingTitle}}</div>
							<div class="project">所属项目：{{item.projectName}}</div>
							<div class="time">
								会议时间：
								{{$moment(item.meetingStartTime).format('L') }}
								{{$moment(item.meetingStartTime).format('LT') }}
							</div>
							<div :class="`type ${item.meetingStatus}`" v-if="item.meetingStatus == 'WAIT'">待开始</div>
							<div :class="`type ${item.meetingStatus}`" v-if="item.meetingStatus == 'FINISH'">已结束</div>
							<div :class="`type ${item.meetingStatus}`" v-if="item.meetingStatus == 'LIVING'">进行中</div>
						</div>
						<div class="opts">
							<el-button class="details" @click="toHuiyiDetails(item)">会议详情</el-button>
							<el-button v-if="item.isSign == 'NO'" class="agreement"
								@click.stop="toagreement(item)">签署协议</el-button>
							<!-- <el-button v-if="item.meetingStatus =='WAIT' || item.meetingStatus =='LIVING'"
								class="details" @click.stop="enterTheMeeting(item)">进入会议</el-button> -->
						</div>
					</div>
				</div>
				<div class="null" v-if="!meetingList[0]">
					<img src="../../assets/imgs/EmptyState.png" alt="">
					<p>暂无预定会议</p>
				</div>
			</div>
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="size"
				:current-page.sync="page" @current-change="getMyMeeting" :hide-on-single-page="false"></el-pagination>
		</div>
		<el-dialog title="参会签到" :visible.sync="joinDialog" v-if="joinDialog" width="400px" :center="true">
			<el-form class="join loginForm" :model="joinform" label-width="60px" label-position="top" :rules="rules"
				ref="ruleForm">
				<el-form-item label="姓名" prop="userName">
					<el-input v-model="joinform.userName" placeholder="请输入姓名" clearable></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phoneNumber">
					<el-input v-model="joinform.phoneNumber" placeholder="请输入手机号" maxlength="11" clearable>
						<p slot="append" @click="sendCodeFun(joinform.phoneNumber)">{{btnText}}</p>
					</el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="verifyCode">
					<el-input v-model="joinform.verifyCode" placeholder="请输入验证码" clearable> </el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="joinDialog = false">取消</el-button>
				<el-button type="primary" @click="signInAndEnterTheMeeting('ruleForm')">签到并进入会议</el-button>
			</span>
		</el-dialog>
		<el-dialog class="agreementDlog" :visible.sync="AgreementShow" v-if="AgreementShow" width="300px"
			:center="true">
			<img src="../../assets/imgs/EmptyState2.png" alt="">
			<p class="info">
				您有
				<span>{{AgreementList.length}}</span>
				份协议等待签署
			</p>
			<el-button type="primary" @click="toHuiyiDetails({id:AgreementList[0]})">现在去签署</el-button>
		</el-dialog>
	</div>
</template>

<script>
	import {
		signIn,
		sendCode,
		doctorMeetList,
		getDoctorInfo,
		getAgreementNum,
		doctorTodayMeeting,
		doctorGetMeetingUrl
	} from '@/request/api.js';
	const validateMobile = (rule, value, callback) => {
		let valueReg = /^1[3-9]\d{9}$/;
		if (!valueReg.test(value)) {
			callback(new Error('请输入正确的手机号'));
		} else {
			callback();
		};
	};
	export default {
		name: 'huizhen',
		components: {},
		data() {
			return {
				page: 1,
				size: 10,
				total: 0,
				curNow: "WAIT",
				meetingList: [],
				todayMeet: '',
				// list: [{
				// 	name: '待参会',
				// 	class: 'WAIT',
				// }, {
				// 	name: '已结束',
				// 	class: 'FINISH',
				// }],
				message: '',
				joinDialog: false,
				joinform: {
					meetingId: '',
					signLiveMeetingId: '',
					userName: '',
					phoneNumber: '',
					verifyCode: ''
				},
				btnText: "获取验证码",
				second: 60,
				rules: {
					phoneNumber: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							validator: validateMobile.bind(this),
							trigger: 'blur'
						}
					],
					userName: [{
						required: true,
						message: '请输入姓名',
						trigger: 'change'
					}],
					verifyCode: [{
						required: true,
						message: '请输入验证码',
						trigger: 'change'
					}]
				},
				rowItem: '',
				AgreementList: '',
				AgreementShow: false,

				searchVal: '',
				searchVal2: ''
			}
		},
		created() {
			this.getDoctorInfoFun();
			this.getMyMeeting();
			this.getAgreementNumFun();
		},
		mounted() {},
		methods: {
			// 获取待签署协议
			getAgreementNumFun() {
				getAgreementNum().then(res => {
					if (res.code == 200 && res.data.length) {
						this.AgreementList = res.data;
						this.AgreementShow = true;
					};
				});
			},
			getDoctorInfoFun() {
				getDoctorInfo().then(res => {
					if (res.code == 200) {
						this.joinform.userName = res.data.fullName;
						this.joinform.phoneNumber = res.data.phoneNumber;
					};
				});
			},

			search() {
				this.page = 1;
				this.getMyMeeting();
			},

			getMyMeeting() {
				let data = {
					current: this.page,
					size: this.size,
					meetingStatus: this.curNow,
				};
				if (this.searchVal) data.meetingTitle = this.searchVal;
				if (this.searchVal2) data.meetingStartTime = this.searchVal2;
				doctorMeetList(data).then(res => {
					if (res.code == 200) {
						this.meetingList = res.data.records;
						this.total = res.data.total;
					};
				});
				doctorTodayMeeting().then(res => {
					if (res.code == 200) {
						this.todayMeet = res.data;
					};
				});
			},
			// 进入会议
			enterTheMeeting(item) {
				this.rowItem = item;
				this.joinform.signLiveMeetingId = item.id;
				this.joinform.meetingId = item.meetingId;
				this.joinDialog = false;
				let data = {
					meetingId: item.id
				};
				this.rowItem.join = true; // 取消签到
				if (this.rowItem.join) {
					doctorGetMeetingUrl(data).then(res => {
						if (res.code == 200) {
							// console.log(res.data)
							window.open(res.data);
						} else {
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定',
								type: 'info',
								closeOnClickModal: true
							});
						};
					});
				} else {
					this.joinDialog = true;
				};
			},
			// 签到并进入会议
			signInAndEnterTheMeeting(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						signIn(this.joinform).then(res => {
							if (res.code == 200) {
								this.rowItem.join = true;
								this.enterTheMeeting(this.rowItem);
								this.$notify({
									title: "签到成功",
									message: "签到成功",
									position: 'bottom-right',
									type: 'success'
								});
							};
						});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 跳转会诊详情
			toHuiyiDetails(item) {
				this.$router.push({
					name: 'dmeetdetail',
					query: {
						mId: item.id
					}
				});
			},
			// 去签署协议
			toagreement(item) {
				this.$router.push({
					name: 'agreement',
					query: {
						mId: item.id
					}
				});
			},
			// 获取验证码
			sendCodeFun(e) {
				let valueReg = /^1[3-9]\d{9}$/;
				if (!e) {
					this.$notify.error({
						title: '错误',
						message: '请输入手机号',
						position: 'bottom-right'
					});
				} else if (!valueReg.test(e)) {
					this.$notify.error({
						title: '错误',
						message: '请输正确的入手机号',
						position: 'bottom-right'
					});
				} else {
					sendCode(this.joinform.phoneNumber).then(res => {
						this.getCode();
						console.log(res);
					}).catch(err => {
						console.log(err);
					});
				};
			},
			getCode() {
				this.btnText = `${this.second}秒后再次获取`;
				this.second--;
				if (this.second < 0) {
					this.btnText = '获取验证码';
					this.second = 60;
				} else {
					setTimeout(() => {
						this.getCode();
					}, 1000);
				};
			},
			// 切换会诊选项
			tabsChange(item) {
				this.curNow = item.class;
				this.status = item.meetingStatus;
				this.getMyMeeting();
			},
		}
	}
</script>
<style lang="scss" scoped>
	#huizhen {
		display: flex;
		flex-direction: column;

		.search {
			display: flex;
			align-items: center;
			margin: 20px 20px 0;

			.el-input {
				margin-right: 30px;
				flex: 1;
			}

			.el-date-picker {
				flex: 1;
			}

			.el-button {
				width: 120px;
			}
		}

		.hzBanner {
			min-height: 140px;
			background-color: rgba(20, 71, 205, 1);
		}

		.hzCont {
			flex: 1;
			padding: 20px;
			display: flex;
			background-color: #fff;
			flex-direction: column;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;

			.tabs {
				display: flex;
				margin-bottom: 20px;
				flex-direction: row;
				align-items: center;
				padding-bottom: 20px;
				justify-content: space-around;
				border-bottom: 2px solid #f3f3f3;

				.el-divider {
					width: 2px;
					height: 30px;
				}

				p {
					padding: 5px;
					font-size: 18px;
					cursor: pointer;
					line-height: 2.5;
					position: relative;

					img {
						width: 0;
						left: 50%;
						bottom: 0;
						height: 0;
						position: absolute;
						transition: all .3s;
						transform: translate(-50%, 50%);
					}
				}

				.active img {
					width: 60px;
					height: 24px;
				}
			}

			.list {
				flex: 1;

				h1 {
					color: #000;
					font-size: 20px;
					margin-bottom: 20px;
				}

				.el-carousel {
					margin-bottom: 10px;
				}

				.el-carousel__item {
					display: flex;
					flex-direction: row;

					.bgImg {
						flex: 1;
						height: 100%;
						overflow: hidden;
						object-fit: cover;
						border-radius: 5px;

						img {
							width: 100%;
							height: 100%;
							display: block;
							object-fit: cover;
						}
					}

					.main {
						flex: 1;
						padding: 20px 0 20px 40px;
						display: flex;
						flex-direction: column;
						align-items: space-between;
						justify-content: space-between;

						>div {
							font-size: 16px;
							display: flex;
							align-items: center;
							color: #112950;

							img {
								height: 16px;
								margin-right: 5px;
							}
						}

						.tit {
							font-size: 22px;
							color: #000;
							word-break: break-all;
							overflow: hidden;
							text-overflow: ellipsis;
							-webkit-line-clamp: 2;
							display: -webkit-box;
							-webkit-box-orient: vertical;

						}

						.goLive {
							color: #fff;
							height: 50px;
							background-color: #D43030;
						}
					}
				}


				.itemToday {
					display: flex;
					margin-bottom: 20px;
					flex-direction: row;
					padding: 20px;
					box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
					transition: all .3s;
					border-radius: 10px;
					align-items: center;

					&:hover {
						background-color: #E8EEFC;
					}

					.main {
						flex: 1;
						display: flex;
						flex-direction: column;

						.tag {
							display: flex;
							margin-bottom: 10px;

							span {
								height: 20px;
								background-color: #007AFF;
								font-size: 12px;
								border-radius: 5px;
								color: #fff;
								display: flex;
								align-items: center;
								padding: 0 10px;
							}
						}

						.tit {
							width: 100%;
							font-size: 16px;
							color: #000;
							margin-bottom: 10px;
							line-height: 1;
							word-break: break-all;
							overflow: hidden;
							text-overflow: ellipsis;
							-webkit-line-clamp: 2;
							display: -webkit-box;
							-webkit-box-orient: vertical;
						}

						.time {
							font-size: 13px;
							color: #007AFF;
							line-height: 1;
						}
					}

					.goLive {
						background: #0065FF;
						color: #fff;
						padding: 0 25px;
						height: 28px;
					}
				}

				.item {
					display: flex;
					margin-bottom: 20px;
					flex-direction: column;
					padding: 20px;
					box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
					transition: all .3s;
					border-radius: 10px;
					cursor: pointer;

					* {
						cursor: pointer;
					}

					&:hover {
						background-color: #E8EEFC;
					}

					.tags {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 10px;

						.type {
							display: flex;
							align-items: center;
							color: #000;
							font-size: 12px;

							img {
								margin-right: 5px;
								width: 20px;
								height: 15px;
							}
						}

						.idNum {
							font-size: 12px;
							color: #999;
						}
					}

					.main {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						.left {
							flex: 1;
							margin-right: 20px;

							.tit {
								font-size: 18px;
								color: #000;
								margin-bottom: 10px;
								text-align: justify;
								// line-height: 1;
								word-break: break-all;
								overflow: hidden;
								text-overflow: ellipsis;
								-webkit-line-clamp: 2;
								display: -webkit-box;
								-webkit-box-orient: vertical;
							}

							.time,
							.project {
								font-size: 14px;
								color: #666;
								line-height: 1;
								margin-bottom: 10px;
							}

							.type {
								display: flex;
								color: #2A82E4;
							}

							.FINISH {
								color: #999;
							}

							.LIVING {
								color: green;
							}
						}

						.opts {
							display: flex;
							align-items: flex-end;

							.details {
								background-color: #007AFF;
								color: #fff;
								padding: 0 25px;
								height: 28px;
							}

							.agreement {
								background-color: #D43030;
								color: #fff;
								padding: 0 25px;
								height: 28px;
							}
						}
					}
				}

				.null {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 100%;
					border-radius: 10px;
					padding: 20px;
					box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
					margin-bottom: 20px;

					img {
						width: 200px;
					}

					p {
						font-size: 18px;
						color: #A6A6A6;
					}
				}
			}

			.el-pagination {
				margin: auto;
			}
		}


		.agreementDlog {
			display: flex;
			align-items: center;
			justify-content: center;

			::v-deep .el-dialog {
				margin: 0 !important;
				border-radius: 10px;

				.el-dialog__header {
					padding: 0;
				}

				.el-dialog__body {
					padding: 24px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					img {
						width: 140px;
					}

					.info {
						font-size: 18px;
						margin-top: 20px;

						span {
							color: #D43030;
						}
					}

					.el-button {
						width: 100%;
						margin-top: 20px;
					}
				}
			}
		}
	}
</style>