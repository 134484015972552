<template>
	<div id="batchCreateMeet" v-loading="loading" :element-loading-text="loadingText">
		<div class="top">
			<div class="title">
				<i></i>
				批量创建会议
			</div>
			<div class="options">
				<el-link type="primary" target="_blank"
					href="//life-oasis.oss-cn-beijing.aliyuncs.com/video/%E6%89%B9%E9%87%8F%E5%88%9B%E5%BB%BA%E4%BC%9A%E8%AE%AE.xlsx">下载模板</el-link>
				<el-upload class="batchCreateMeetBtn" :action="`${baseUrl}/sign/live/official/importProjectMeeting`"
					:headers="{token:token}" :data="{projectId:projectId}" :on-progress="Progress"
					:on-success="handleSuccess" :show-file-list="false">
					<el-button type="primary" size="mini">上传文件</el-button>
				</el-upload>
			</div>
		</div>

		<div class="list">
			<!-- 列表 -->
			<div class="title">
				导入会议预览
			</div>
			<el-table :data="tableData" style="width: 100%">
				<el-table-column fixed label="编号" width="60" type="index"></el-table-column>
				<el-table-column label="会议时间" width="160">
					<template slot-scope="scope">
						{{$moment(scope.row.meetingStartTime).format('L')}}
						{{$moment(scope.row.meetingStartTime).format('LT')}}
					</template>
				</el-table-column>
				<el-table-column prop="meetingTitle" label="会议主题" width="240"></el-table-column>
				<el-table-column prop="offices" label="项目官员" min-width="200">
					<template slot-scope="scope">
						<!-- {{scope.row.offices}} -->
						<el-select disabled v-model="scope.row.offices" multiple placeholder="请设置项目官员" size="mini">
							<el-option v-for="item in officerList" :key="item.id" :label="item.fullName"
								:value="item.id">
							</el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column prop="laborActuatorIds" label="劳务助教" min-width="200">
					<template slot-scope="scope">
						<!-- {{scope.row.laborActuatorIds}} -->
						<el-select disabled v-model="scope.row.laborActuatorIds" multiple placeholder="请设置劳务助教"
							size="mini">
							<el-option v-for="item in assistantList" :key="item.id" :label="item.fullName"
								:value="item.id">
							</el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column prop="meetingActuatorIds " label="会务助教" min-width="200">
					<template slot-scope="scope">
						<!-- {{scope.row.meetingActuatorIds}} -->
						<el-select disabled v-model="scope.row.meetingActuatorIds" multiple placeholder="请设置会务助教"
							size="mini">
							<el-option v-for="item in assistantList" :key="item.id" :label="item.fullName"
								:value="item.id">
							</el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column prop="meetingActuatorId " label="执行机构" min-width="250">
					<template slot-scope="scope">
						<template v-for="item in orgList" v-if="item.id == scope.row.meetingActuatorId">
							{{item.deptName}}
						</template>
					</template>
				</el-table-column>
				<el-table-column prop="meetingMechanismId  " label="立项单位" min-width="250">
					<template slot-scope="scope">
						<template v-for="item in MechanismList" v-if="item.id == scope.row.meetingMechanismId">
							{{item.deptName}}
						</template>
					</template>
				</el-table-column>

				<el-table-column fixed="right" label="操作" min-width="70" align="center">
					<template slot-scope="scope">
						<el-button @click="changeMeetingInfo(scope.$index)" type="text" size="small">修改</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 	<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
				:page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="total" background>
			</el-pagination> -->
			<div class="options" v-if="tableData[0]">
				<el-button type="primary" @click="createAMeetingSunmit">确认批量创建会议</el-button>
			</div>
		</div>

		<el-dialog custom-class="batchCreateMeetDialog" :visible.sync="batchCreateMeet" v-if="batchCreateMeet"
			:append-to-body="true">
			<div class="title">
				创建完成
			</div>
			<i class="el-icon-success"></i>
			<p>会议已批量创建完成，<el-link type="primary" disabled>返回我的会议查看</el-link></p>
			<div class="dialogFooter">
				<el-button type="primary" @click="batchCreateMeet = false">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="修改会议信息" custom-class="changemeetingInfo" :visible.sync="chengeMeetingInfoDialog"
			v-if="chengeMeetingInfoDialog" :append-to-body="true">
			<div class="meetiingInfo">
				<!-- {{meetingRow}} -->
				<el-form ref="form" label-position="top" :model="meetingRow" label-width="300px">
					<el-form-item label="会议名称">
						<el-input v-model="meetingRow.meetingTitle"></el-input>
					</el-form-item>
					<el-form-item label="项目官员">
						<el-select v-model="meetingRow.offices" multiple placeholder="请设置项目官员">
							<el-option v-for="item in officerList" :key="item.id" :label="item.fullName"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="劳务助教">
						<el-select v-model="meetingRow.laborActuatorIds" multiple placeholder="请设置劳务助教">
							<el-option v-for="item in assistantList" :key="item.id" :label="item.fullName"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="会务助教">
						<el-select v-model="meetingRow.meetingActuatorIds" multiple placeholder="请设置劳务助教">
							<el-option v-for="item in assistantList" :key="item.id" :label="item.fullName"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<div class="dialogFooter">
				<el-button type="primary" @click="chengeMeetingCheck">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		importProjectMeeting,
		getofficialUserList, // 获取部门内部人员列表
		officialActuatorList,
		officialtMechanismList,
		officialEnterPriseList,
		saveProjectMeeting
	} from '@/request/api.js';
	import baseUrl from "@/request/config.js"
	export default {
		name: 'batchCreateMeet',
		data() {
			return {
				loading: false,
				loadingText: '加载中···',

				baseUrl: '',
				token: '',
				projectId: '',
				tableData: [],
				// total: 300,
				// pageSize: 10,
				// current: 1,

				MechanismList: [],
				enterPriseList: [],
				orgList: [],
				assistantList: [],
				officerList: [],

				meetingRow: '',
				rowIndex: '',

				batchCreateMeet: false,
				chengeMeetingInfoDialog: false
			}
		},
		created() {
			this.baseUrl = baseUrl;
			this.token = JSON.parse(localStorage.getItem('token')).token;
			this.projectId = JSON.parse(localStorage.getItem('projectDetails')).id;
			this.officialActuatorListFun();
		},
		mounted() {},
		methods: {
			// 确认创建会议并提交
			createAMeetingSunmit() {
				let data = this.tableData;
				saveProjectMeeting(data).then(res => {
					if (res.code == 200) {
						this.$alert('批量创建会议成功。', '操作成功', {
							confirmButtonText: '确定',
							center: true,
							type: 'success',
							callback: action => {
								this.$router.push({
									name: "officialMeetinglist",
									query: {
										pId: this.projectId
									}
								});
							}
						});
					}
				})

			},

			// 修改会议信息确定
			chengeMeetingCheck() {
				this.tableData[this.rowIndex] = this.meetingRow;
				this.chengeMeetingInfoDialog = false;
				this.$forceUpdate();
			},
			// 修改会议信息
			changeMeetingInfo(idx) {
				this.rowIndex = idx;
				this.meetingRow = this.tableData[idx];
				this.chengeMeetingInfoDialog = true;
			},

			// 批量上传文件成功
			handleSuccess(res, file) {
				this.loading = false;
				if (res.code == 200) {
					this.tableData = res.data;
					// 提示 导入成功
					this.$alert('请在完成后续相关操作后点击提交进行保存。', '数据读取成功', {
						confirmButtonText: '确定',
						center: true,
						type: 'success',
					});
					this.getDepartmentList()
				} else {
					
					// 提示 导入失败
					this.$alert(res.msg, '读取失败', {
						confirmButtonText: '确定',
						center: true,
						type: 'error',
					});
				}
			},
			officialActuatorListFun() {
				//获取立项单位列表
				officialtMechanismList().then(res => {
					if (res.code == 200) {
						this.MechanismList = res.data.map(item => {
							item.id = item.id.toString()
							return item
						});
					}
				})

				// 获取支持企业列表
				officialEnterPriseList().then(res => {
					if (res.code == 200) {
						this.enterPriseList = res.data.map(item => {
							item.id = item.id.toString()
							return item
						});
					}
				})

				// 获取执行机构列表
				officialActuatorList().then(res => {
					if (res.code == 200) {
						this.orgList = res.data.map(item => {
							item.id = item.id.toString()
							return item
						});
					}
				})
			},
			// 查询部门内人员列表
			getDepartmentList() {
				// 助教列表
				getofficialUserList({
					actuatorId: this.tableData[0].meetingActuatorId
				}).then(res => {
					if (res.code == 200) {
						this.assistantList = res.data.map(item => {
							item.id = item.id.toString()
							return item
						});
					};
				});
				// 官员列表
				getofficialUserList({
					mechanismId: this.tableData[0].meetingMechanismId
				}).then(res => {
					if (res.code == 200) {
						console.log(res.data)
						this.officerList = res.data.map(item => {
							item.id = item.id.toString()
							return item
						});
					};
				});

			},
			// 分页 pagesize 变化
			handleSizeChange() {
				console.log(`每页 ${val} 条`);
			},
			// 分页 current 变化
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
			},

			Progress() {
				this.loading = true;
				this.loadingText = '导入中，请稍后...';
			},
		}
	}
</script>

<style lang="scss" scoped>
	#batchCreateMeet {
		padding: 20px 30px;

		.top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: #fff;
			border-radius: 10px;
			padding: 15px 20px;

			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

			.title {
				font-size: 20px;
				padding: 0 20px 0 30px;
				position: relative;

				i {
					top: 50%;
					left: 0px;
					width: 20px;
					height: 20px;
					position: absolute;
					border-radius: 50%;
					background-color: #3563E9;
					border: 5px solid #C2D0F8;
					transform: translateY(-50%);
				}
			}

			.options {
				display: flex;
				align-items: center;

				.el-button {
					margin-left: 20px;
				}

				.el-link {
					font-size: 12px;
				}
			}
		}

		.list {
			background-color: #fff;
			border-radius: 10px;
			padding: 20px;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			margin: 20px 0;

			.title {
				font-size: 16px;
				position: relative;
				margin-bottom: 10px;

			}

			.el-pagination {
				text-align: center;
				margin-top: 20px;
			}

			.options {
				display: flex;
				justify-content: center;
				margin-top: 20px;

				.el-button {}
			}
		}
	}

	::v-deep .batchCreateMeetDialog {
		margin: 0 !important;
		left: 50%;
		top: 50%;
		width: 400px;
		transform: translate(-50%, -50%);
		max-height: 95vh;
		overflow-y: auto;

		.el-dialog__header {
			padding: 0 !important;
		}

		.el-dialog__body {
			display: flex;
			flex-direction: column;
			align-items: center;

			.title {
				font-size: 20px;
			}

			.el-icon-success {
				font-size: 50px;
				margin: 20px;
			}

			p {
				font-size: 14px;
			}

			.dialogFooter {
				margin-top: 20px;
			}
		}
	}

	::v-deep .changemeetingInfo {
		margin: 0 !important;
		left: 50%;
		top: 50%;
		width: 450px;
		transform: translate(-50%, -50%);
		max-height: 95vh;
		overflow-y: auto;

		.dialogFooter {
			display: flex;
			justify-content: center;
		}

		.el-dialog__body {
			padding: 20px;
		}

		.el-select {
			width: 100%;
		}

		.el-form-item {
			margin-bottom: 20px;
		}

		.el-form-item__label {
			padding: 0;
			line-height: 1;
			margin-bottom: 10px;
		}
	}
</style>

<style lang="scss">

</style>