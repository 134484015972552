<template>
	<div id="changeInfo">
		<!-- 修改个人信息 -->
		<el-form :model="form" :rules="rules" ref="form" label-position="top">
			<el-row :gutter="20" class="row1">
				<h1>修改个人信息</h1>
				<el-col :span="12">
					<el-form-item label="姓名：" prop="fullName">
						<el-input clearable v-model="form.fullName" placeholder="请输入用户姓名"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="手机号码：" prop="hospital">
						<el-input disabled clearable v-model="form.phoneNumber" placeholder="请输入所在医院"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="身份证号：" prop="cardId">
						<el-input clearable v-model="form.cardId" placeholder="请输入身份证号" maxlength="18"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="银行卡号：" prop="bankNumber">
						<el-input clearable v-model="form.bankNumber" placeholder="请输入银行卡"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="开户行：" prop="bankName">
						<el-input clearable v-model="form.bankName" placeholder="请输入开户行"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="所在地区：">
						<el-cascader v-model="form.location" :options="regionTree" clearable style="width: 100%;"
							:props="{ value:'name', label:'name'}"></el-cascader>
					</el-form-item>
				</el-col>
				<!-- 	<el-col :span="12">
					<el-form-item label="城市(地区)：" prop="city">
						<el-input clearable v-model="form.city" placeholder="请输入所在医院"></el-input>
						<el-select v-model="form.city" placeholder="请选择所在城市(地区)" clearable :disabled="!form.province">
							<el-option v-for="item in cityTree" :key="item.name" :label="item.name" :value="item.name">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col> -->
				<el-col :span="12">
					<el-form-item label="医院：" prop="hospital">
						<el-input clearable v-model="form.hospital" placeholder="请输入所在医院"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="科室：" prop="department">
						<el-select v-model="form.department" placeholder="请选择所属科室" clearable filterable>
							<el-option v-for="item in department" :key="item.name" :label="item.name"
								:value="item.name">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职称：" prop="title">
						<el-select v-model="form.title" placeholder="请选择职称" clearable filterable>
							<el-option v-for="item in title" :key="item.name" :label="item.name" :value="item.name">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<!-- <el-col :span="12">
					<el-form-item label="关联项目：" prop="project">
						<el-select v-model="form.projectId" placeholder="请选择职称" filterable>
							<el-option v-for="item in projectList" :key="item.id"
								:label="`${item.projectName}${item.projectStatus=='TIMEOUT'?'(已结束)':''}`"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col> -->
				<el-col :span="24">
					<p class="opts">
						<el-button type="primary" style="width: 180px;" @click="submit">确认提交</el-button>
					</p>
				</el-col>
			</el-row>
			<el-row :gutter="40" class="row2">
				<h1>修改四证信息</h1>
				<el-col :span="12">
					<el-form-item label="身份证人像面：">
						<el-upload
							:action="`${baseUrl}${token.type=='Doctor'?'/sign/live/doctor/uploadIdCard':'/sign/live/assistant/uploadIdCard'}`"
							class="avatar-uploader" :show-file-list="false" :on-success="handleAvatarSuccess1"
							:headers="{token:token.token}" :data={id:form.id} :before-upload="beforeAvatarUpload">
							<img v-if="imageUrl['1']" :src="imageUrl['1'].file" class="avatar">
							<img v-else class="el-icon-plus" src="../../assets/imgs/idCard_rx.png" alt="">
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="身份证国徽面：">
						<el-upload class="avatar-uploader" :action="`${baseUrl}/sign/live/doctor/uploadFile`"
							:show-file-list="false" :on-success="handleAvatarSuccess2" :headers="{token:token.token}"
							:data={id:form.id} :before-upload="beforeAvatarUpload">
							<img v-if="imageUrl['2']" :src="imageUrl['2'].file" class="avatar">
							<img v-else class="el-icon-plus" src="../../assets/imgs/idCard_gh.png" alt="">
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="银行卡卡号面：">
						<el-upload
							:action="`${baseUrl}${token.type=='Doctor'?'/sign/live/doctor/uploadBankCard':'/sign/live/assistant/uploadBankCard'}`"
							class="avatar-uploader" :show-file-list="false" :on-success="handleAvatarSuccess3"
							:headers="{token:token.token}" :data={id:form.id} :before-upload="beforeAvatarUpload">
							<img v-if="imageUrl['3']" :src="imageUrl['3'].file" class="avatar">
							<img v-else class="el-icon-plus" src="../../assets/imgs/backCard.png" alt="">
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职业资格证照片：">
						<el-upload class="avatar-uploader" :action="`${baseUrl}/sign/live/doctor/uploadFile`"
							:show-file-list="false" :on-success="handleAvatarSuccess4"
							:before-upload="beforeAvatarUpload">
							<img v-if="imageUrl['4']" :src="imageUrl['4'].file" class="avatar">
							<img v-else class="el-icon-plus" src="../../assets/imgs/zs.png" alt="">
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
	import {
		getDoctorInfo,
		information,
		getDoctorById,
		changeInfobyId,
		getProjectList,
		projectList
	} from '@/request/api.js';
	import regionTree from '@/assets/regionTree.json';
	import department from '@/assets/department.json';
	import title from '@/assets/title.json';
	import baseUrl from "@/request/config.js"
	export default {
		name: 'changeInfo',
		components: {},
		data() {
			return {
				loading: false,
				baseUrl: '',
				token: '',
				docId: '',
				form: {
					fullName: '',
					hospital: '',
					department: '',
					title: '',
					cardId: '',
					bankNumber: '',
					bankName: '',
					province: '',
					city: ''
				},
				imageUrl: {
					'1': '',
					'3': '',
					'4': '',
				},
				rules: {
					fullName: [{
						required: true,
						message: '请输入姓名',
						trigger: 'change'
					}],
					hospital: [{
						required: false,
						message: '请输入在职医院',
						trigger: 'change'
					}],
					department: [{
						required: false,
						message: '请填写科室',
						trigger: 'change'
					}],
					title: [{
						required: false,
						message: '请填写职务',
						trigger: 'change'
					}],
					project: [{
						required: false,
						message: '请关联项目',
						trigger: 'change'
					}],
					cardId: [{
						required: true,
						message: '请输入验证码',
						trigger: 'change'
					}, {
						validator: (rule, value, callback) => {
							if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
								callback(new Error('请输入正确的身份证号!'));
							} else {
								callback();
							}
						},
						trigger: 'blur'
					}, {
						min: 15,
						max: 18,
						message: '请输入正确的身份证号!',
						trigger: 'blur'
					}],
					bankNumber: [{
						required: true,
						message: '请输入银行卡号',
						trigger: 'change'
					}],
					bankName: [{
						validator: (rule, value, callback) => {
							if (!value || value.includes('支行')) {
								callback();
							} else {
								callback(new Error('开户行需要完善到支行!'));
							}
						},
						trigger: 'change'
					}]
				},
				dialogVisible: false,
				fileList: [],
				regionTree: '',
				cityTree: '',
				projectList: []
			}
		},
		created() {
			this.baseUrl = baseUrl;
			this.regionTree = regionTree;
			this.department = department;
			this.title = title;
			this.echo();
		},
		mounted() {},
		methods: {
			// 回显前置
			echo() {
				let dId = this.$route.query.dId;
				if (dId) {
					this.docId = dId;
					this.getDoctorByIdFun();
				} else {
					this.token = JSON.parse(localStorage.getItem('token'));
					if (this.token.type == 'Doctor') this.getDocInfo();
				};
			},
			getProjectListFun() {
				let data = {
					projectId: '',
					projectStatus: 'UNDERWAY',
					size: 500
				};
				if (this.form.projectId) data.projectName = this.form.projectId;
				// projectList(data).then(res => {
				getProjectList(data).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.projectList = res.data.records;
					};
				});
			},
			provinceChange(val) {
				this.form.city = '';
				this.regionTree.map(item => {
					if (item.name == val) {
						this.cityTree = item.city;
					};
				});
			},
			submit() {
				let data = this.form;
				let paths = [];
				for (let i in this.imageUrl) {
					if (this.imageUrl[i].path) {
						let obj = {
							"fileName": this.imageUrl[i].path,
							fileType: ''
						};
						if (i == 1) {
							obj.fileType = 'idCard'
						} else if (i == 2) {
							obj.fileType = 'idCardBack'
						} else if (i == 3) {
							obj.fileType = 'bankCard'
						} else if (i == 4) {
							obj.fileType = 'certificate'
						};
						paths.push(obj);
					};
				};
				data.uploadFiles = JSON.stringify(paths);
				if (this.docId) {
					data.id = this.docId;
					console.log(JSON.parse(JSON.stringify(data)), '助教');
					changeInfobyId(data).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '提示',
								type: 'success',
								message: '修改成功',
								position: 'bottom-right'
							});
							this.$router.go(-1);
						} else {
							this.$notify({
								title: '错误',
								type: 'error',
								message: res.msg,
								position: 'bottom-right'
							});
						};
					});
				} else {
					information(data).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '提示',
								type: 'success',
								message: '修改成功',
								position: 'bottom-right'
							});
							this.$router.go(-1);
						} else {
							this.$notify({
								title: '错误',
								type: 'error',
								message: res.msg,
								position: 'bottom-right'
							});
						};
					});
				};
			},
			getDoctorByIdFun() {
				let data = {
					id: this.docId
				};
				getDoctorById(data).then(res => {
					this.form = res.data;
					if (res.data.files) {
						res.data.files.map(item => {
							// console.log(item.fileType);
							if (item.fileType == "idCard") {
								this.imageUrl['1'] = {
									file: item.filePath,
									path: item.filePath
								};
							};
							if (item.fileType == "idCardBack") {
								this.imageUrl['2'] = {
									file: item.filePath,
									path: item.filePath
								};
							};
							if (item.fileType == "bankCard") {
								this.imageUrl['3'] = {
									file: item.filePath,
									path: item.filePath
								};
							};
							if (item.fileType == "certificate") {
								this.imageUrl['4'] = {
									file: item.filePath,
									path: item.filePath
								};
							};
						});
					};
					// this.getProjectListFun();
				});
			},
			getDocInfo() {
				getDoctorInfo().then(res => {
					if (res.code == 200) {
						this.form = res.data;
						if (res.data.files) {
							res.data.files.map(item => {
								// console.log(item.fileType);
								if (item.fileType == "idCard") {
									this.imageUrl['1'] = {
										file: item.filePath,
										path: item.filePath
									};
								};
								if (item.fileType == "idCardBack") {
									this.imageUrl['2'] = {
										file: item.filePath,
										path: item.filePath
									};
								};
								if (item.fileType == "bankCard") {
									this.imageUrl['3'] = {
										file: item.filePath,
										path: item.filePath
									};
								};
								if (item.fileType == "certificate") {
									this.imageUrl['4'] = {
										file: item.filePath,
										path: item.filePath
									};
								};
							});
						};
					};
					// this.getProjectListFun();
				});
			},
			handleAvatarSuccess1(res, file) {
				// console.log(JSON.parse(JSON.stringify(res)));
				if (res.data.cardId) {
					this.imageUrl['1'] = {
						file: URL.createObjectURL(file.raw),
						path: res.data.fileName
					};
					this.form.fullName = res.data.fullName;
					this.form.cardId = res.data.cardId;
				} else {
					this.$alert('身份证识别失败，请更换清晰的图片', '提示', {
						confirmButtonText: '确定',
						closeOnClickModal: true,
						type: "error",
						center: true
					});
				};
			},
			handleAvatarSuccess2(res, file) {
				this.imageUrl["2"] = {
					file: URL.createObjectURL(file.raw),
					path: res
				};
			},
			handleAvatarSuccess3(res, file) {
				// console.log(JSON.parse(JSON.stringify(res)));
				if (res.data.bankNumber) {
					this.imageUrl['3'] = {
						file: URL.createObjectURL(file.raw),
						path: res.data.fileName
					};
					this.form.bankNumber = res.data.bankNumber;
					this.form.bankName = res.data.bankName;
				} else {
					this.$alert('银行卡识别失败，请更换清晰的图片', '提示', {
						confirmButtonText: '确定',
						closeOnClickModal: true,
						type: "error",
						center: true
					});
				};
			},
			handleAvatarSuccess4(res, file) {
				this.imageUrl['4'] = {
					file: URL.createObjectURL(file.raw),
					path: res,
				};
			},
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt20M = file.size / 10204 / 10204 < 20;
				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt20M) {
					this.$message.error('上传头像图片大小不能超过 20MB!');
				};
				return isLt20M;
			},
		}
	}
</script>

<style lang="scss" scoped>
	#changeInfo {
		padding: 30px 20px;

		h1 {
			font-size: 22px;
			margin-bottom: 30px;
		}

		.el-form {
			width: 1000px;
			box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
			padding: 50px;
			border-radius: 10px;
			display: flex;

			.el-row {
				flex: 3;
			}

			.row2 {
				flex: 2;
				padding-left: 30px;
			}

			.el-form-item {
				display: flex;
				flex-direction: column;

				* {
					line-height: 1;
				}
			}

			.el-form-item__label {
				text-align: left;
			}

			.el-select {
				width: 100%;
			}

			.avatar {
				width: 130px;
				height: 130px;
				border-radius: 10px;
			}

			.avatar-uploader-icon {
				width: 130px;
				height: 130px;
				display: flex;
				border-radius: 10px;
				align-items: center;
				justify-content: center;
				border: 1px dashed #ccc;
			}

			.el-upload {
				height: 130px;
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			.el-upload {
				img {
					box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
					width: 180px;
					height: 120px;
					border-radius: 5px;
					overflow: hidden;
					cursor: pointer;
					object-fit: cover;
				}

				.el-upload--text {}
			}
		}

		.opts {
			text-align: center;
			margin: 20px 50px 0;
		}
	}
</style>