<template>
	<div id="login">
		<div class="loginBox">
			<div class="banner">
				<div class="logo">
					<!-- <img src="../../assets/imgs/logo3.png" alt=""> -->
					<img src="../../assets/logo3.png" alt="">
				</div>
				<img class="back" src="../../assets/imgs/loginBack.png">
			</div>
			<div class="box">
				<h1>欢迎登录</h1>
				<el-form :model="form" label-width="100%" label-position="top" :rules="rules" ref="ruleForm"
					class="loginForm">
					<el-form-item label="手机号" prop="phoneNumber">
						<el-input v-model="form.phoneNumber" placeholder="请输入手机号" maxlength="11" clearable></el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="verifyCode" v-if="loginType == 'code'">
						<el-input v-model="form.verifyCode" placeholder="请输入验证码" clearable
							@keyup.native.enter="submitForm('ruleForm')">
							<p slot="append" @click="sendCode(form.phoneNumber)">{{btnText}}</p>
						</el-input>
					</el-form-item>
					<el-form-item label="密码" prop="passWord" v-if="loginType == 'pwd'">
						<el-input v-model="form.passWord" type="password" placeholder="请输入密码" clearable
							@keyup.native.enter="submitForm('ruleForm')"></el-input>
					</el-form-item>
				</el-form>
				<div class="other">
					<b @click="changeLoginType()">使用其他方式登录</b>
					<el-link :underline="false" type="primary" v-if="loginType == 'pwd'">忘记密码</el-link>
				</div>

				<div class="opts">
					<el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
					<p>
						还没有账号？<el-button type="text" @click="goToRegister()">去注册</el-button>
					</p>

				</div>

				<div class="userAgreement">
					<el-checkbox v-model="checked">我已阅读并同意 <a @click.stop="agreementShow=true">用户使用协议</a></el-checkbox>
				</div>
			</div>
		</div>

		<el-dialog custom-class="agreementShow" title="用户使用协议" v-if="agreementShow" :visible.sync="agreementShow"
			width="800px" center>
			<div class="main">
				<h4>一、协议概述</h4>
				<br>1.1
				本协议是您（以下简称“用户”）与我们（以下简称“服务提供方”）之间关于使用“万木智会”系统（以下简称“本系统”）所订立的协议。本协议旨在明确用户与服务提供方之间的权利和义务，规范用户使用本系统的行为。
				<br><br>1.2 您在使用本系统提供的服务前，请仔细阅读并充分理解本协议的全部内容。一旦您开始使用本系统，即表示您同意遵守本协议的所有条款和条件。
				<br><br>1.3 服务提供方保留随时修改本协议的权利。修改后的协议一经公布即生效。若您不同意相关变更，请立即停止使用本系统；否则，视为您接受修改后的协议。
				<br>
				<h4>二、用户信息的收集与使用 </h4>
				<br><br>2.1 信息收集范围
				<br>为了向您提供更好的服务，本系统将收集以下信息：
				<br>· 个人身份信息：包括姓名、性别、出生日期、身份证号码、职业资格证等；
				<br>· 联系方式：包括电话号码、电子邮箱等；
				<br>· 职业相关信息：包括就业医院、科室、职称等；
				<br>· 验证信息：通过人脸识别等方式进行身份验证。
				<br><br>2.2 信息使用目的
				<br>收集的信息将仅用于以下用途：
				<br>· 提供核心服务功能，如电子协议签署与支付进度查询；
				<br>· 确保身份真实性和账户安全；
				<br>· 遵守法律法规及监管要求；
				<br>· 提供个性化服务体验。
				<br><br>2.3 信息保护措施
				<br>· 服务提供方承诺采取严格的数据保护措施，防止信息被泄露、滥用或未经授权访问；
				<br>· 所有敏感信息将通过加密技术进行存储和传输，确保数据安全。
				<br><br>2.4 信息共享与披露
				<br>在以下情况下，我们可能会共享您的信息：
				<br>· 与为完成服务所必要的第三方（如支付机构、认证机构）共享；
				<br>· 根据法律法规的要求披露；
				<br>· 为保护用户及服务提供方的合法权益。
				<br>
				<h4>三、用户的权利与义务</h4>
				<br><br>3.1 用户的权利
				<br>· 查询与修改：您有权随时查询、更正您的个人信息；
				<br>· 删除与撤回：您可要求删除部分信息或撤回某些授权，但可能导致部分功能不可用；
				<br>· 监督与投诉：如发现信息被误用或泄露，您可立即通知服务提供方并要求采取措施。
				<br><br>3.2 用户的义务
				<br>· 妥善保管您的账户信息，不得将账户信息提供给他人；
				<br>· 遵守法律法规和本协议，不利用本系统从事任何违法活动；
				<br>· 提供真实、准确的信息，因提供虚假信息导致的后果由您自行承担。
				<br>
				<h4>四、服务使用规则</h4>
				<br><br>4.1 禁止行为
				<br>在使用本系统时，用户不得从事以下行为：
				<br>· 上传、传播违法、侵权、虚假的内容；
				<br>· 干扰或破坏本系统的正常运行；
				<br>· 未经授权访问、修改或删除数据。
				<br><br>4.2 处理措施
				<br>对于违反上述规则的行为，服务提供方有权采取警告、限制功能、暂停或终止服务等措施，并保留追究法律责任的权利。
				<br>
				<h4>五、法律责任</h4>
				<br><br>5.1 服务提供方责任
				<br>若因服务提供方违反法律法规或本协议导致用户权益受损，服务提供方将依法承担赔偿责任。
				<br><br>5.2 用户责任
				<br>因用户违反本协议或相关法律法规导致服务提供方或第三方遭受损失的，用户应承担相应赔偿责任。
				<br><br>5.3 免责条款
				<br>因不可抗力、第三方原因或用户自身过失导致的损失，服务提供方不承担责任。
				<br>
				<h4>六、其他条款</h4>
				<br><br>6.1 协议解释权
				<br>本协议的解释权归服务提供方所有。
				<br><br>6.2 争议解决
				<br>本协议适用中华人民共和国法律。因本协议引起的争议，双方应协商解决；协商不成的，可向服务提供方所在地有管辖权的法院提起诉讼。
				<br><br>6.3 协议独立性
				<br>若本协议部分条款被认定为无效或不可执行，其余条款仍保持有效。
				<br><br>6.4 用户通知
				<br>服务提供方将通过本系统或其他渠道发布协议更新及通知，用户有责任及时查阅。
				<br>
				<br>请您在使用本系统前仔细阅读本协议。如有疑问，请联系客户服务。感谢您对“万木智会”系统的支持与信任！
				<br>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="agreementShow = false">取 消</el-button>
				<el-button type="primary" @click="agreementShow = false;checked = true">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		login,
		sendCode,
		getDoctorInfo
	} from '@/request/api.js';
	const validateMobile = (rule, value, callback) => {
		let valueReg = /^1[3-9]\d{9}$/;
		if (!valueReg.test(value)) {
			callback(new Error('请输入正确的手机号'));
		} else {
			callback();
		};
	};
	export default {
		name: 'Home',
		components: {},
		data() {
			return {
				checked: true,
				agreementShow: false,
				loginType: 'pwd',
				btnText: "获取验证码",
				second: 60,
				form: {
					phoneNumber: '',
					passWord: '',
					verifyCode: ''
				},
				rules: {
					phoneNumber: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							validator: validateMobile.bind(this),
							trigger: 'blur'
						}
					],
					passWord: [{
						required: true,
						message: '请输入密码',
						trigger: 'change'
					}],
					verifyCode: [{
						required: true,
						message: '请输入验证码',
						trigger: 'change'
					}]
				}
			}
		},
		watch: {},
		created() {},
		mounted() {},
		methods: {
			// 去注册
			goToRegister() {
				this.$router.push({
					name: 'register'
				});
			},
			// 获取验证码
			sendCode(e) {
				let valueReg = /^1[3-9]\d{9}$/;
				if (!e) {
					this.$notify.error({
						title: '错误',
						message: '请输入手机号',
						position: 'bottom-right'
					});
				} else if (!valueReg.test(e)) {
					this.$notify.error({
						title: '错误',
						message: '请输正确的入手机号',
						position: 'bottom-right'
					});
				} else {
					if (this.second >= 60) {
						sendCode(this.form.phoneNumber).then(res => {
							this.getCode();
							console.log(res);
						}).catch(err => {
							console.log(err);
						});
					};
				};
			},
			getCode() {
				this.btnText = `${this.second}秒后再次获取`;
				this.second--;
				if (this.second < 0) {
					this.btnText = '获取验证码';
					this.second = 60;
				} else {
					setTimeout(() => {
						this.getCode();
					}, 1000);
				};
			},
			// 提交登录
			submitForm(formName) {
				if (this.checked) {

					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.loginFun();
						};
					});
				} else {
					// this.$notify.error({
					// 	title: `错误`,
					// 	message: '请勾选用户使用协议',
					// 	position: 'bottom-right'
					// });

					this.$alert('请勾选用户使用协议', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
						center: true
					});
				}
			},
			loginFun() {

				let data = {
					phoneNumber: this.form.phoneNumber
				};

				if (this.loginType == 'code') {
					data.verifyCode = this.form.verifyCode;
				} else if (this.loginType == 'pwd') {
					data.password = this.form.passWord;
				};

				login(data).then(res => {
					console.log("login", res)
					if (res && res.code == 200) {
						localStorage.setItem('token', JSON.stringify(res.data))
						if (res.data.type == 'Doctor') {
							// 医生用户完善信息验证
							this.getDoctorInfoFun();
						} else if (res.data.type == 'ASSISTANT') {
							this.$router.push({
								name: 'project'
							});
						} else if (res.data.type == 'OFFICIAL') {
							this.$router.push({
								name: 'official'
							});
						};
					} else {
						this.$notify.error({
							title: `错误`,
							message: res.msg,
							position: 'bottom-right'
						});
					};
				});

			},

			getDoctorInfoFun() {
				getDoctorInfo().then(res => {
					if (res.code == 200) {
						let userInfo = res.data;
						if (!userInfo.password) {
							this.$notify({
								title: `提示`,
								message: '请先设置登录密码',
								position: 'bottom-right',
								type: 'warning'
							});
							this.$router.push({
								name: 'setpwd'
							});
						} else if (userInfo.isUpload == "NULL") {
							this.$notify({
								title: `提示`,
								message: '请完成个人认证',
								position: 'bottom-right',
								type: 'warning'
							});
							this.$router.push({
								name: 'upLoadInfo'
							});
						} else if (!userInfo.fullName) {
							this.$notify({
								title: `提示`,
								message: '请完善个人信',
								position: 'bottom-right',
								type: 'warning'
							});
							this.$router.push({
								name: 'ImproveInfo'
							});
						} else if (userInfo.status == 'ERROR') {
							this.$alert('您的注册请求被驳回，请检查并完善信息后重新注册。', '提示', {
								confirmButtonText: '确定',
								type: 'info',
								center: true,
								callback: action => {
									this.$router.push({
										name: 'ImproveInfo'
									});
								}
							});
						} else if (!userInfo.projectId) {
							this.$notify({
								title: `提示`,
								message: '请选择要参加的项目',
								position: 'bottom-right',
								type: 'warning'
							});
							this.$router.push({
								name: 'SelectProject'
							});
						} else {
							this.$router.push({
								name: 'meetlist'
							});
						};
					};
				});
			},
			// 切换登录状态
			changeLoginType() {
				if (this.loginType == 'code') {
					this.loginType = 'pwd';
				} else {
					this.loginType = 'code';
				};
			}
		}
	}
</script>

<style lang="scss" scoped>
	#login {
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(244, 249, 253, 1);

		.loginBox {
			width: 90%;
			max-width: 1200px;
			height: 90%;
			display: flex;
			overflow: hidden;
			border-radius: 20px;
			flex-direction: row;
			box-shadow: 0 0 20px rgba(0, 0, 0, .1);

			.banner,
			.box {
				flex: 1;
				background-color: #fff;
			}


			.banner {
				// background-color: rgba(63, 140, 255, 1);
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				position: relative;

				.logo {
					flex: 1;
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					position: absolute;
					top: 40px;
					left: 40px;

					img {
						height: 80px;
					}
				}

				.back {
					width: 400px;
					height: 500px;
					object-fit: contain;
				}
			}

			.box {
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				h1 {
					font-size: 20px;
					margin-bottom: 30px;
				}

				.loginForm {
					width: 70%;
					display: flex;
					flex-direction: column;

					* {
						font-size: 14px;
					}

					.el-input {
						height: 40px;
					}
				}

				.other {
					width: 70%;
					display: flex;
					margin: 30px 0;
					flex-direction: row;
					justify-content: space-between;

					* {
						font-size: 13px;
					}

					b {
						cursor: pointer;
						font-weight: normal;
					}
				}

				.opts {
					width: 70%;
					display: flex;
					align-items: center;
					margin-bottom: 10px;
					flex-direction: column;
					justify-content: center;

					.el-button {
						width: 120px;
					}

					p {
						color: #B2BAC6;
						margin-top: 20px;

						.el-button {
							padding: 0;
							width: auto;
							color: #2A82E4;
						}
					}
				}
			}
		}
	}

	::v-deep .agreementShow {
		margin: 0 !important;
		max-height: 80vh;
		overflow: hidden;
		overflow-y: scroll;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		.main {
			text-align: justify;
		}
	}

	// ::v-deep .el-dialog__body {
	// 	padding: 0 !important;
	// }

	// ::v-deep .el-dialog__header {
	// 	padding: 0 !important;
	// }

	// ::v-deep .el-dialog__headerbtn {
	// 	top: 10px;
	// 	right: 10px;
	// }
</style>