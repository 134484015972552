<template>
	<div id="meetdetails">
		<div v-loading="loading" :element-loading-text="loadingText">
			<div class="top">
				<div class="title">会议主题：{{MeetingDetail.meetingTitle}} <i></i> </div>
				<div class="tabs" v-if="meetStatus == 'WAIT'||meetStatus == 'WAIT_CLAIM'||meetStatus == 'LIVING'">
					<template v-for="(item,i) in list2">
						<p :class="item.class == curNow ? 'active':''" :key="i" @click="tabsChange(item)">
							<b :class="`tab${item.class}`">{{item.name}}</b>
							<i></i>
						</p>
						<el-divider direction="vertical" :key="i" v-if="i<list.length-1"></el-divider>
					</template>
				</div>
				<div class="tabs" v-else>
					<template v-for="(item,i) in list">
						<p :class="item.class == curNow ? 'active':''" :key="i" @click="tabsChange(item)">
							<b :class="`tab${item.class}`">{{item.name}}</b>
							<i></i>
						</p>
						<el-divider direction="vertical" :key="i" v-if="i<list.length-1"></el-divider>
					</template>
				</div>
			</div>
			<div class="laborProgress" label="费用明细" v-if="curNow=='1'">
				<div class="lpBox">
					<el-row class="tabTitle">
						<el-col :span="6">
							<span>姓名</span>
						</el-col>
						<el-col :span="6">
							<span>参会身份</span>
						</el-col>
						<el-col :span="6">
							<span>税前</span>
						</el-col>
						<el-col :span="6">
							<span>税后</span>
						</el-col>
					</el-row>
					<el-row class="tabContent" v-for="(item,i) in orderList.orders" :key="i">
						<el-col :span="6">
							<span>{{item.fullName}}</span>
						</el-col>
						<el-col :span="6">
							<span>{{item.roleName}}</span>
						</el-col>
						<el-col :span="6">
							<span class="preTax">¥{{item.preTax?item.preTax:0}}</span>
						</el-col>
						<el-col :span="6">
							<span class="afterTax">¥{{item.afterTax?item.afterTax:0}}</span>
						</el-col>
					</el-row>
					<el-row class="endNode">
						<el-col :span="6">
							<span>小计：</span>
						</el-col>
						<el-col :span="6" :offset="6">
							<span class="preAmount">¥{{orderList.preAmount?orderList.preAmount:0}}</span>
						</el-col>
						<el-col :span="6">
							<span class="amount">¥{{orderList.amount?orderList.amount:0}}</span>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="conferenceReplay" label="劳务状态" v-if="curNow=='2'">˝
				<div class="crBox">
					<el-row class="tabTitle">
						<el-col :span="6">
							<span>姓名</span>
						</el-col>
						<el-col :span="6">
							<span>签署情况</span>
						</el-col>
						<el-col :span="6">
							<span>支付进度</span>
						</el-col>
						<el-col :span="6">
							<span>操作</span>
						</el-col>
					</el-row>
					<el-row class="tabContent" v-for="(item,i) in agreementList" :key="i">
						<el-col :span="6">
							<span>{{item.fullName}}</span>
						</el-col>
						<el-col :span="6">
							<el-tag size="mini" v-if="item.status=='WAIT'" type="warning">待签署</el-tag>
							<el-tag size="mini" v-else type="success">已签署</el-tag>
						</el-col>
						<el-col :span="6">
							<template v-if="item.orderStatus=='WAIT'">
								<el-tag size="mini" type="warning">待支付</el-tag>
							</template>
							<el-tooltip v-else-if="item.orderStatus=='ERROR'||item.orderStatus=='REJECT'" class="item"
								:content="`驳回原因：${item.errorMessage}`" placement="top">
								<el-tag size="mini" type="danger">支付驳回</el-tag>
							</el-tooltip>
							<template v-else>
								<el-tag size="mini" type="success">已支付</el-tag>
							</template>
						</el-col>
						<el-col :span="6">
							<el-button @click="sendTextFun(item)" size='mini' type="danger"
								v-if="item.isSend=='NO'&&item.status=='WAIT'">
								短信提醒
							</el-button>
							<el-button @click="sendTextFun(item)" size='mini' type="danger"
								v-else-if="item.isSend=='YES'&&item.status=='WAIT'">
								再次提醒
							</el-button>
							<el-button size='mini' type="text" v-else>
								无
							</el-button>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="meetingDetails" label="会议详情" v-if="curNow=='3'">
				<div class="left">
					<el-row :gutter="20">
						<el-col :span="24">
							<p>会议主题</p>
							<div>{{MeetingDetail.meetingTitle}}</div>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :span="24">
							<p>所属项目</p>
							<div>{{MeetingDetail.projectName}}</div>
						</el-col>
					</el-row>
					<el-row :gutter="20" v-if="MeetingDetail.reimbursementNum">
						<el-col :span="24">
							<p>金蝶单号</p>
							<div>{{MeetingDetail.reimbursementNum}}</div>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :span="12">
							<p>会议类型</p>
							<div v-if="MeetingDetail.meetingFormat == 'ONLINE'">万木线上会</div>
							<div v-else-if="MeetingDetail.meetingFormat == 'TENCENT'">腾讯线上会</div>
							<div v-else-if="MeetingDetail.meetingFormat == 'OFFLINE'">线下会</div>
							<div v-else>仅管理</div>
						</el-col>
						<el-col :span="12">
							<p>会议日期</p>
							<div>
								{{$moment(MeetingDetail.meetingStartTime).format('lll')}}

							</div>
						</el-col>
					</el-row>

					<el-row :gutter="20" v-if="MeetingDetail.meetingFormat == 'OFFLINE'&&MeetingDetail.location">
						<el-col :span="12">
							<p>参会地点</p>
							<div>{{MeetingDetail.location.map(item=>item.trim()).join('/')}}</div>
						</el-col>
						<el-col :span="12">
							<p>详细地址</p>
							<div>{{MeetingDetail.position}}</div>
						</el-col>
					</el-row>
					<!-- <el-row :gutter="20" v-if="roleList">
					
				</el-row> -->
					<el-row :gutter="20" v-if="roleList">
						<el-col>
							<p>参会人员</p>
						</el-col>
						<el-col :span="24" v-for="(item,i) in MeetingDetail.users" :key="i">
							<p>{{roleList[i]}}</p>
							<div>
								<template v-for="(item2,k) in item">
									{{item2.fullName}}
									<template v-if="k < item.length-1">,</template>
								</template>
							</div>
						</el-col>
					</el-row>
				</div>

				<div class="center">
					<el-row :gutter="20">
						<el-col :span="24">
							<p>立项单位</p>
							<div>{{MechanismList.deptName}}</div>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :span="24">
							<p>项目官员</p>
							<div v-if="MeetingDetail.officesIds">
								<template v-for="item in executive">
									<template v-if="user == item.id" v-for="(user,idx) in MeetingDetail.officesIds">
										{{item.fullName}}
										<template v-if="idx<MeetingDetail.officesIds.length -1">,</template>
									</template>
								</template>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="20" v-if="enterPriseList.deptName">
						<el-col :span="24">
							<p>支持企业</p>
							<div>
								{{enterPriseList.deptName}}
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :span="24">
							<p>执行机构</p>
							<div>
								{{orgList.deptName}}
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :span="24">
							<p>会务负责人</p>
							<div v-if="MeetingDetail.meetingActuatorIds">
								<template v-for="item in orgListUser">
									<template v-if="user == item.id"
										v-for="(user,idx) in MeetingDetail.meetingActuatorIds">
										{{item.fullName}}
										<template v-if="idx<MeetingDetail.meetingActuatorIds.length -1">,</template>
									</template>
								</template>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :span="24">
							<p>劳务负责人</p>
							<div v-if="MeetingDetail.laborActuatorIds">
								<template v-for="item in orgListUser">
									<template v-if="user == item.id"
										v-for="(user,idx) in MeetingDetail.laborActuatorIds">
										{{item.fullName}}
										<template v-if="idx<MeetingDetail.laborActuatorIds.length -1">,</template>
									</template>
								</template>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="right">
					<el-row :gutter="20">
						<el-col :span="24">
							<p>直播间背景图</p>
							<div>
								<el-image :src="MeetingDetail.bgImage" fit="cover"
									:preview-src-list="srcList"></el-image>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :span="24">
							<p>会议主题</p>
							<div>
								<el-image :src="MeetingDetail.bill" fit="cover" :preview-src-list="srcList"></el-image>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="postMeetingUpload" label="会后上传" v-if="curNow=='4'">
				<div class="personalSettlement">
					<div class="card" @click="uploadPostMeetingDocuments">
						<i class="el-icon-s-claim"></i>
						个人劳务结算
					</div>
				</div>
				<!-- <div class="supplierSettlement"
					v-if="MeetingDetail.meetingStatus!='WAIT'&&MeetingDetail.meetingStatus!='WAIT_CLAIM'&&MeetingDetail.meetingStatus!='LIVING'">
					<div class="card" @click="settleAccountsShow = true" v-if="!orderData">
						<i class="el-icon-s-finance"></i>
						供应商结算
					</div>
					<template v-else>
						<div class="card" @click="supplierSettlement" v-if="orderData.status=='WAIT'">
							<i class="el-icon-document-remove"></i>
							已保存去提交
						</div>
						<div class="card" @click="supplierSettlement" v-if="orderData.status=='SUBMIT'">
							<i class="el-icon-folder-opened"></i>
							已提交去查看
						</div>
						<div class="card" @click="supplierSettlement" v-if="orderData.status=='SUCCESS'">
							<i class="el-icon-folder-checked"></i>
							审核通过去查看
						</div>
						<div class="card" @click="supplierSettlement" v-if="orderData.status=='ERROR'">
							<i class="el-icon-folder-delete"></i>
							审核驳回去修改
						</div>
						<div class="card" @click="supplierSettlement" v-if="orderData.status=='FINISH'">
							<i class="el-icon-tickets"></i>
							审核通过去查看
						</div>
						<div class="card" @click="settleAccountsShow = true" v-if="orderData.status=='ERROR'">
							<i class="el-icon-s-finance"></i>
							重新提交
						</div>
					</template>
				</div> -->
			</div>
			<div class="participationLink" label="参会链接" v-if="curNow=='5'">
				<!-- <p>{{MeetingDetail.codeUrl}}</p>
							<p>{{MeetingDetail.inviteCodeUrl}}</p>
							<p>{{MeetingDetail.liveUrl}}</p> -->
				<div class="row">
					<div class="col">
						<div class="qrcode">
							<h4>会议签到
								<span>点击二维码下载</span>
							</h4>
							<img :src="MeetingDetail.signCodeUrl" alt=""
								@click="downloadQrCode(MeetingDetail.signCodeUrl,'签到二维码')">
						</div>
					</div>

					<div class="col">
						<div class="qrcode">
							<h4>劳务签署
								<span>点击二维码下载</span>
							</h4>
							<img :src="MeetingDetail.inviteCodeUrl" alt=""
								@click="downloadQrCode(MeetingDetail.inviteCodeUrl,'劳务二维码')">
						</div>
					</div>

					<div class="col">
						<div class="qrcode">
							<h4>观看直播
								<span>点击二维码下载</span>
							</h4>
							<img :src="MeetingDetail.codeUrl" alt=""
								@click="downloadQrCode(MeetingDetail.codeUrl,'直播二维码')">
						</div>
						<div class="linkUrl" v-if="MeetingDetail.liveUrl">
							<h4>直播链接
							</h4>
							<a :href="MeetingDetail.liveUrl" target="_blank">{{MeetingDetail.liveUrl}}</a>
						</div>
					</div>

					<!-- <div class="col">
					<div class="qrcode">
						<h4>签到二维码（会前签到）</h4>
						<img :src="MeetingDetail.signCodeUrl" alt="">
					</div>
				</div> -->
				</div>
			</div>
			<div class="livePlayback" label="直播回放" v-if="curNow=='6'">
				<el-upload class="uploadVideo" :action="`${baseUrl}/sign/live/assistant/uploadMeetingVideo`"
					:data="{meetingId:$route.query.mId}" :headers="{'token':token}" :multiple="false"
					:file-list="videoList" :show-file-list="false" :on-success="videoSuccess"
					:before-upload="videoBeforeUpload">
					<el-button size="small" type="primary">上传直播回放</el-button>
				</el-upload>
				<el-table :data="MeetingDetail.videos" key="videTable" style="width: 100%">
					<el-table-column prop="id" label="id" width="100" align="cneter"></el-table-column>
					<el-table-column label="名称" align="cneter">
						<template slot-scope="scope">
							【{{MeetingDetail.meetingTitle}}】
							会议录像： {{scope.row.fileName}}
						</template>
					</el-table-column>
					<el-table-column label="操作" width="300" align="cneter">
						<template slot-scope="scope">
							<el-button size="mini" @click="viewVideo(scope.row)">查看</el-button>
							<el-button size="mini" @click="downloadVideo(scope.row)">下载</el-button>
							<el-button size="mini" @click="deleteVideo(scope.row)">删除</el-button>
							<el-dialog custom-class="agreementDialog" v-if="viewVideoType" title="查看回放"
								:visible.sync="viewVideoType" width="1000px">
								<video :src="showVideo" controls autoplay></video>
							</el-dialog>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="options">
				<el-button size="small" class="back" @click="back()">返回列表</el-button>
				<el-button size="small"
					v-if="(MeetingDetail.meetingStatus=='WAIT'||MeetingDetail.meetingStatus=='LIVING')&&MeetingDetail.meetingFormat!='OTHER'"
					type="primary" class="enter" @click.stop="enterTheMeeting()">进入会议</el-button>
				<!-- <el-button size="small" plain type="primary" class="back" @click="upMeetingFile()"
				v-if="(MeetingDetail.meetingStatus=='UPLOAD'||MeetingDetail.meetingStatus=='ERROR'||MeetingDetail.meetingStatus=='AI_REVIEW_ERROR'||MeetingDetail.meetingStatus=='OFFICIAL_ERROR')&&curNow==4">提交会后文件</el-button> -->
				<el-button size="small" plain type="primary" @click="ChangeMeetingDetail()"
					v-if="MeetingDetail.meetingStatus=='WAIT'">修改会议信息</el-button>
				<el-button size="small" plain type="danger" @click="ChangeMeetingDetail()"
					v-if="MeetingDetail.meetingStatus=='WAIT_CLAIM'">完善信息</el-button>
				<el-button size="small" plain type="warning" @click="delMeetingFun()"
					v-if="MeetingDetail.meetingStatus=='WAIT'&&MeetingDetail.createdId == userInfo.id">取消会议</el-button>
				<el-button size="small" plain type="danger" class="enter" @click.stop="closeTheMeeting()"
					v-if="MeetingDetail.meetingStatus=='WAIT'||(MeetingDetail.meetingStatus=='LIVING'&&MeetingDetail.meetingFormat!='ONLIEN')">结束会议</el-button>
			</div>
		</div>
		<!-- 项目进度 -->
		<el-dialog class="projectSchedule" v-if="projectSchedule" :visible.sync="projectSchedule" title="跟进项目进度"
			width="800px">
			<!-- 修改项目进度 -->
			<template>
				<el-table :data="MeetingDetail.logs" style="width: 100%">
					<el-table-column label="编辑日期" align="center" width="150px">>
						<template slot-scope="scope">
							<el-date-picker :disabled="scope.$index!==0&&!MeetingDetail.logs[scope.$index-1].updateTime"
								size="small" style="width: 100%;" v-model="scope.row.updateTime" type="date"
								placeholder="选择日期" value-format="timestamp">
							</el-date-picker>
						</template>
					</el-table-column>
					<el-table-column label="进度状态" align="center" width="120px">
						<template slot-scope="scope">
							<!-- <el-checkbox v-model="scope.row.statusVal">{{scope.row.status}}</el-checkbox> -->
							{{scope.row.status}}
						</template>
					</el-table-column>
					<el-table-column label="操作者" align="center" width="80px">
						<template slot-scope="scope">
							<span class="user">{{scope.row.actuatorFullName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="备注" align="center">
						<template slot-scope="scope">
							<el-input :disabled="scope.$index!==0&& !MeetingDetail.logs[scope.$index-1].updateTime"
								clearable type="textarea" :rows="2" autosize placeholder="请输入备注："
								v-model="scope.row.message"></el-input>
						</template>
					</el-table-column>
				</el-table>
			</template>
			<span slot="footer" class="dialog-footer">
				<el-button @click="projectSchedule = false">取 消</el-button>
				<el-button type="primary" @click="updateMeetingProgress">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 回款记录 -->
		<el-dialog v-if="paymentRecord" :visible.sync="paymentRecord" title="合同回款变更" width="800px">
			合同回款变更
			<span slot="footer" class="dialog-footer">
				<el-button @click="paymentRecord = false">取 消</el-button>
				<el-button type="primary" @click="paymentRecord = false">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 供应商结算 -->
		<el-dialog custom-class="settleAccounts" v-if="settleAccountsShow" :visible.sync="settleAccountsShow"
			title="请选择收款方式及费用类型" width="600px" center>
			<el-form ref="form" :model="accountForm" label-position="left">
				<el-row>
					<el-col :span="12">
						<el-form-item label="收款方式:">
							<el-select size="small" v-model="accountForm.collectWay" placeholder="请选择活动区域">
								<el-option v-for="(item,i) in collectWayEnums" :key="i" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="费用类型:">
							<el-select size="small" v-model="accountForm.feeType" placeholder="请选择活动区域">
								<el-option label="会议类" value="0"></el-option>
								<el-option label="非会议类" value="1" disabled></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="tips">
				<h3>请仔细阅读以下说明</h3>
				<p>如有 <b>一次性收款</b> ，直接选择“一次性收款并结项”</p>
				<p>如有 <b>两次收款</b> ，第一次选择“申请首次收款”，第二次选择“申请尾款”</p>
				<p>如有 <b>分三次及三次以上收款</b> ，第一次选择“申请首次收款”，中间付款选择“申请阶段收款”，最后一笔选择“申请尾款”</p>
				<p><b>据实结算</b> 的收款方式仅支持费用类型是会议类，框架服务模式，按场次收款的项目，可多次申请</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="supplierSettlement">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		FormItem
	} from 'element-ui';
	import pdf from "vue-pdf";
	import {
		sendText,
		meetingOver,
		getMeetRoleList,
		getAgreementList,
		getUpdateMeetInfo,
		orderListAssistant,
		getMeetingUrl,
		uploadMeetingFile,
		getMeetingFile,
		delMeetingFile,
		delMeeting,
		getOrgList,
		getMechanismList,
		getEnterPriseList,
		getAssistantUserList,
		updateMeetingStatus,
		getCollectWay,
		getOrderByMeetId,
		delMeetingVideo,
		getAssistantInfo
	} from '@/request/api.js';
	import baseUrl from "@/request/config.js"
	export default {
		name: 'meetdetails',
		data() {
			return {
				loading: false,
				loadingText: '',
				baseUrl: '',
				curNow: "3",
				list: [{
						name: '会议详情',
						class: '3',
					},
					{
						name: '费用明细',
						class: '1',
					},
					{
						name: '劳务状态',
						class: '2',
					},
					{
						name: '直播回放',
						class: '6',
					},
					{
						name: '会议链接',
						class: '5',
					},
					{
						name: '会后结算',
						class: '4',
					}
				],
				list2: [{
						name: '会议详情',
						class: '3',
					},
					{
						name: '费用明细',
						class: '1',
					},
					{
						name: '劳务状态',
						class: '2',
					},
					{
						name: '会议链接',
						class: '5',
					}
				],
				srcList: [],
				meetingId: '',
				activeName: '1',
				MeetingDetail: '',
				userInfo: '',
				agreementList: [],
				orderList: '', // 费用明细
				roleList: '',
				template: '',
				meetStatus: '',
				dialogVisible: false,
				dialogFileUrl: {},
				viewVideoType: false, // 查看视频回放
				showVideo: '',
				annotation: {
					file1: '会议通知',
					file2: '会议日程',
					file3: '会议照片',
					file4: '签到表',
					file5: '劳务协议',
					file6: '劳务协议明细表',
				},
				MechanismList: [], // 立项单位列表
				projectOriginator: '',
				orgList: [], // 执行机构列表
				orgListUser: [], // 执行机构人员列表
				executive: '',
				enterPriseList: [], // 支持企业列表
				supportStaff: '',
				projectSchedule: false, // 项目进度
				paymentRecord: false, // 回款记录
				errorMsg: {},
				currentPage: 1, // 当前页码
				numPages: 0, // 设置总页码
				settleAccountsShow: false, // 结算记录
				accountForm: {
					feeType: '0',
					collectWay: ""
				},
				collectWayEnums: [],
				orderData: '',
				token: '',
				videoList: []
			}
		},
		components: {
			pdf,
		},
		created() {
			this.baseUrl = baseUrl;
			this.meetStatus = this.$route.query.status;
			this.meetingId = this.$route.query.mId;
			this.curNow = this.$route.query.now;

			this.getOrgListFun();
			this.getCollectWayFun();
			this.token = JSON.parse(localStorage.getItem('token')).token
			this.getAssistantInfoFun()
		},
		mounted() {
			this.getMeetInfo();
			this.getMeetFile();
		},
		methods: {
			getAssistantInfoFun() {
				getAssistantInfo().then(res => {
					console.log(res);
					this.userInfo = res.data;
				})
			},

			async downloadQrCode(url, name) {

				console.log(filePath, name)
				// 下载图片
				let a = document.createElement('a')
				a.href = link
				a.download = name
				a.click(); // 自动触发点击事件下载文件

			},

			deleteVideo(row) {
				console.log(row);
				// 确认弹窗
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delMeetingVideo({
						id: row.id
					}).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.getMeetInfo();
						} else {
							this.$message.error(res.msg);
						}
					})
				})
			},
			downloadVideo(row) {
				window.open(row.videoPath)
			},
			// 查看视频回放
			viewVideo(row) {
				this.viewVideoType = true;
				this.showVideo = row.videoPath;
			},
			// 去上传会后文件
			uploadPostMeetingDocuments() {
				this.$router.push({
					name: 'uploadFile',
					query: {
						mId: this.meetingId
					}
				})
			},
			getCollectWayFun() {
				getCollectWay({
					projectId: this.projectId,
					meetingId: this.meetingId
				}).then(res => {
					if (res.code == 200) {
						console.log(res.data)
						this.collectWayEnums = res.data;
						if (this.collectWayEnums.length == 1) {
							this.accountForm.collectWay = this.collectWayEnums[0].value;
						}
					};
				});
			},
			// 跳转供应商结算页面
			supplierSettlement() {
				this.$router.push({
					name: 'supplierSettlement',
					query: {
						mId: this.meetingId,
						collectWay: this.accountForm.collectWay,
						feeType: this.accountForm.feeType
					}
				})
			},
			// 更新会议进度
			updateMeetingProgress() {
				let data = JSON.parse(JSON.stringify(this.MeetingDetail.logs));
				for (let i = data.length - 1; i >= 0; i--) {
					if (!data[i].updateTime && !data[i].message) {
						data.splice(i, 1);
					};
				};
				updateMeetingStatus(data).then(res => {
					if (res.code == 200) {
						this.projectSchedule = false;
						// this.getMeetInfo();
					};
				});
			},
			// 删除会议
			delMeetingFun() {
				this.$confirm(`此操作删除会议【${this.MeetingDetail.meetingTitle}】, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					this.loadingText = "加载中···";
					let data = {
						id: this.meetingId
					};
					delMeeting(data).then(res => {
						this.loading = false;
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});

							let projectId = JSON.parse(localStorage.getItem('projectDetails')).id;
							this.$router.push({
								name: 'meetinglist',
								query: {
									pId: projectId
								}
							});
						}
					}).catch(err => {
						this.loading = false;
					})
				});
			},
			// 会后上传文件回显
			getMeetFile() {
				// getMeetingFile({
				// 	meetingId: this.meetingId
				// }).then(res => {
				// 	// console.log(res.data)
				// 	for (let key in res.data.values) {
				// 		this[key] = res.data.values[key].map((item, i) => {
				// 			// console.log(item);
				// 			// console.log(res.data.fileNames[key][i])
				// 			return {
				// 				url: item,
				// 				name: res.data.fileNames[key][i]
				// 			}
				// 		})
				// 		this.errorMsg[key] = res.data.errorMessages[key] ? res.data.errorMessages[key].split(
				// 			'\n') : null;
				// 	}
				// })
			},
			// 上传会后文件
			upMeetingFile() {
				let data = {
					files: {
						file1: this.file1,
						file2: this.file2,
						file3: this.file3,
						file4: this.file4,
						file5: this.file5,
						file6: this.file6
					},
					applyMoney: this.MeetingDetail.applyMoney,
					names: {},
					// joinNums: this.model.peopleNum,
					meetingId: this.meetingId,
					annotation: this.annotation
				};
				for (let i in data.files) {
					data.names[i] = data.files[i].map((item) => {
						return {
							'key': item.response,
							"value": item.name
						}
					});
					data.files[i] = data.files[i].map(item => item.response ? item.response : item.url);
				};
				this.loading = true;
				uploadMeetingFile(data).then(res => {
					this.loading = false;
					if (res.code == 200) {
						// console.log(res);
						this.$router.push({
							name: 'meetinglist',
							query: {
								pId: this.MeetingDetail.projectId
							}
						})
						this.$notify({
							title: '上传成功',
							type: 'success',
							message: '上传成功',
							position: 'bottom-right'
						});
					}
				})
			},
			videoBeforeUpload(file) {
				this.loading = true;
				this.loadingText = '正在上传中 请不要进行其他操作 稍等片刻···'
			},
			videoSuccess(res, file) {
				// this.videoUrl = res.data;
				this.loading = false;
				this.$message({
					message: `文件 ${file.name} 上传成功。`,
					type: 'success'
				});
				this.getMeetInfo();
			},
			handSuccess1(res, file) {
				this.file1.push(file);
				this.$message({
					message: `文件 ${file.name} 上传成功。`,
					type: 'success'
				});
			},
			handSuccess2(res, file) {
				this.file2.push(file);
				this.$message({
					message: `文件 ${file.name} 上传成功。`,
					type: 'success'
				});
			},
			handSuccess3(res, file) {
				this.file3.push(file);
				this.$message({
					message: `文件 ${file.name} 上传成功。`,
					type: 'success'
				});
			},
			handSuccess4(res, file) {
				this.file4.push(file);
				this.$message({
					message: `文件 ${file.name} 上传成功。`,
					type: 'success'
				});
			},
			handSuccess5(res, file) {
				this.file5.push(file);
				this.$message({
					message: `文件 ${file.name} 上传成功。`,
					type: 'success'
				});
			},
			handSuccess6(res, file) {
				this.file6.push(file);
				this.$message({
					message: `文件 ${file.name} 上传成功。`,
					type: 'success'
				});
			},
			// 删除上传的内容
			deletePic(file, idx, key) {
				//删除前确认
				this.$confirm(`此操作将永久删除该文件, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (file.url) {
						delMeetingFile({
							filePath: file.url
						}).then(res => {
							if (res.code == 200) {
								console.log(res.data);
								this[key].splice(idx, 1);
							}
						})
					} else {
						this[key].splice(idx, 1);
					}
				})
			},
			isImage(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
				return imageExtensions.includes(extension);
			},
			isFile(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
				return imageExtensions.includes(extension);
			},
			// handlePreview(file) {
			// 	if (file.url) {
			// 		this.dialogFileUrl = {
			// 			url: file.url,
			// 		};
			// 		if (this.isImage(file.name)) {
			// 			this.dialogFileUrl.type = 'image';
			// 		} else if (this.isFile(file.name)) {
			// 			this.dialogFileUrl.type = 'file';
			// 		} else {
			// 			this.dialogFileUrl.type = 'pdf';
			// 		}
			// 		this.dialogVisible = true;
			// 	} else {
			// 		this.$message.error(`文件 ${file.name} 为同步到服务器，请先确认提交。`);
			// 	}
			// },
			tabsChange(item) {
				// this.page = 1;
				this.curNow = item.class;
				// this.getProjectList();
			},
			// 结束会议
			closeTheMeeting() {
				let data = {
					meetingId: this.meetingId
				}
				this.$confirm('此操作将会结束当前的会议, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					this.loadingText = '加载中···'
					meetingOver(data).then(res => {
						this.loading = false;
						if (res.code == 200) {
							// this.$router.push({
							// 	name: 'meetinglist',
							// 	query: {
							// 		pId: this.MeetingDetail.projectId
							// 	}
							// })
							this.getMeetInfo();
							this.$forceUpdate();
							this.$message({
								type: 'info',
								message: '会议已结束!'
							});
							this.$notify({
								type: 'info',
								title: '提示',
								message: '会议已结束!',
								position: 'bottom-right'
							});
						}
					}).catch(err => {
						this.loading = false;
					})
				});

			},
			// 进入会议
			enterTheMeeting() {
				let data = {
					meetingId: this.meetingId
				}
				getMeetingUrl(data).then(res => {
					if (res.code == 200) {
						window.open(res.data)
					} else {
						this.getMeetInfo();
						this.$message({
							message: res.msg,
							type: 'error'
						});
						this.$notify({
							type: 'error',
							title: '错误',
							message: res.msg,
							position: 'bottom-right'
						});
					}
				})
			},
			// 修改会议信息
			ChangeMeetingDetail() {
				// console.log(JSON.parse(JSON.stringify(this.MeetingDetail)))
				// let projectId = JSON.parse(localStorage.getItem('projectDetails')).id;
				this.$router.push({
					name: 'createmeet',
					query: {
						// pId: projectId,
						mId: this.meetingId
					}
				})
			},
			// 发送提醒签协议短信
			sendTextFun(item) {
				let data = {
					ids: [item.id]
				};
				sendText(data).then(res => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: 'success'
						});
						this.$notify({
							type: 'success',
							title: '发送成功',
							message: res.msg,
							position: 'bottom-right'
						});
						this.getAgreementListFun();
					};
				})
			},
			getOrgListFun() {
				//获取立项单位列表
				getMechanismList().then(res => {
					if (res.code == 200) {
						this.MechanismList = res.data;
						// console.log(this.MechanismList)
					}
				})
				// 获取支持企业列表
				getEnterPriseList().then(res => {
					if (res.code == 200) {
						this.enterPriseList = res.data;
					}
				})
				// 获取执行机构列表
				getOrgList().then(res => {
					if (res.code == 200) {
						console.log(res.data)
						this.orgList = res.data
					}
				})
			},
			// 获取会议信息
			getMeetInfo() {
				let data = {
					meetingId: this.meetingId
				}
				getUpdateMeetInfo(data).then(res => {
					if (res.code == 200) {
						this.MeetingDetail = res.data
						this.srcList = [];
						this.srcList.push(res.data.bgImage);
						this.srcList.push(res.data.bill);
						let data = {};
						let data2 = {};
						let data3 = {};
						this.MechanismList.map(item => {
							if (item.id == this.MeetingDetail.meetingMechanismId) {
								this.MechanismList = item;
							};
						});
						this.enterPriseList.map(item => {
							if (item.id == this.MeetingDetail.meetingEnterpriseId) {
								this.enterPriseList = item;
							};
						});
						this.orgList.map(item => {
							if (item.id == this.MeetingDetail.meetingActuatorId) {
								this.orgList = item;
							};
						});
						// 助教列表
						data.actuatorId = this.MeetingDetail.meetingActuatorId;
						getAssistantUserList(data).then(res => {
							if (res.code == 200) {
								// console.log("助教")
								// console.log(res.data)
								this.orgListUser = res.data
								// let arr = [];
								// this.MeetingDetail.actuatorIds.map(itemId => {
								// 	res.data.map(item => {
								// 		if (item.id == itemId) arr.push(item);
								// 	});
								// });
								// this.MeetingDetail.actuatorIds = arr.map(item => item.fullName);
							};
						});
						// 官员列表
						data3.mechanismId = this.MeetingDetail.meetingMechanismId;
						getAssistantUserList(data3).then(res => {
							if (res.code == 200) {
								this.executive = res.data;
								let arr = [];
								this.MeetingDetail.fundraisingIds.map(itemId => {
									res.data.map(item => {
										if (item.id == itemId) arr.push(item);
									});
								});
								// this.MeetingDetail.fundraisingIds = arr.map(item => item.fullName);
								// let arr2 = [];
								// this.MeetingDetail.officesIds.map(itemId => {
								// 	res.data.map(item => {
								// 		if (item.id == itemId) arr2.push(item);
								// 	});
								// });
								// this.MeetingDetail.officesIds = arr2.map(item => item.fullName);
							}
						})
						getMeetRoleList({
							meetingId: this.MeetingDetail.id
						}).then(res => {
							if (res.code == 200) {
								let roleData = {};
								res.data.map(item => {
									roleData[item.id] = item.roleName
								})
								this.roleList = roleData;
							}
						})
					}
				})
				getOrderByMeetId({
					meetingId: this.$route.query.mId,
				}).then(res => {
					if (res.code == 200) {
						this.orderData = res.data;
					}
				})
				orderListAssistant(data).then(res => {
					if (res.code == 200) {
						this.orderList = res.data;
					}
				})
				this.getAgreementListFun();
			},
			getAgreementListFun() {
				let data = {
					meetingId: this.meetingId
				}
				getAgreementList(data).then(res => {
					console.log(res)
					if (res.code == 200) {

						this.agreementList = res.data;
					}
				})
			},
			signAnAgreement() {
				this.$router.push({
					name: 'agreement'
				});
			},
			// 获取页码总数
			handleNumPages(numPages) {
				this.numPages = numPages;
			},
			// 点击之后页面切换为上一页
			previousPages() {
				this.currentPage--;
			},
			// 点击之后页面切换为上一页
			nextPages() {
				this.currentPage++
			},
			back() {
				// this.$router.go(-1);
				this.$router.push({
					name: 'meetinglist',
					query: {
						pId: this.MeetingDetail.projectId
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	#meetdetails {
		padding: 20px;
		// display: flex;
		// flex-direction: column;
		height: 100%;

		.top {
			padding: 20px 20px;
			margin-bottom: 20px;
			border-radius: 20px;
			box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);
		}

		>.el-alert {
			margin-bottom: 20px;
		}

		.title {
			font-size: 20px;
			padding: 0 20px 0 30px;
			position: relative;

			i {
				top: 50%;
				left: 0px;
				width: 20px;
				height: 20px;
				position: absolute;
				border-radius: 50%;
				background-color: #3563E9;
				border: 5px solid #C2D0F8;
				transform: translateY(-50%);
			}
		}

		.tabs {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;

			.el-divider {
				width: 2px;
				height: 30px;
				margin: 0;
			}

			p {
				width: 100%;
				padding: 20px 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				cursor: pointer;
				line-height: 2.5;
				position: relative;

				i {
					width: 100%;
					left: 0;
					bottom: 0;
					height: 0;
					background-color: #0065FF;
					position: absolute;
					transition: all .3s;
				}

				span {
					margin-left: 10px;
					height: 26px;
					width: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					background-color: #007AFF;
					border-radius: 5px;
					color: #fff;
				}

				b {
					font-weight: normal;
				}

				.tab4 {
					font-weight: bold;
					color: #0065ff;
				}
			}

			.active {
				color: #007AFF;

				i {
					height: 6px;
				}
			}
		}


		.el-tabs__item {
			font-size: 16px;
		}

		.el-tab-pane {
			overflow: hidden;
		}

		.laborProgress {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.lpBox {
				width: 100%;
				padding: 20px;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
				border-radius: 20px;
			}

			.el-row {
				padding: 10px 0;
				margin-bottom: 20px;
			}

			.tabTitle {
				border-bottom: 2px solid #eee;
				width: 100%;

				* {
					font-size: 16px;
				}

				.el-col {
					text-align: center;
					border-right: 1px solid #ccc;

					&:last-child {
						border-right: none;
					}
				}
			}

			.tabContent {
				border-radius: 10px;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
				width: 100%;

				* {
					font-size: 15px;
				}

				.el-col {
					padding: 10px 20px;
					text-align: center;
				}

				.preTax {
					padding: 5px 20px;
					border-radius: 20px;
					background-color: #F1F6FC;
					box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
				}

				.afterTax {
					padding: 5px 20px;
					border-radius: 20px;
					background-color: #F1FCF5;
					box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
				}
			}

			.endNode {
				border-radius: 10px;
				width: 100%;
				margin: 0;

				* {
					font-size: 16px;
					color: #0068FF;
				}

				.el-col {
					padding: 10px 20px;
					text-align: center;
				}

				.preAmount {
					font-size: 20px;
				}

				.amount {
					font-size: 20px;
				}
			}

		}

		.meetingDetails {
			display: flex;
			flex-direction: row;

			.el-row {
				margin: 10px 0;
			}

			.el-col {
				overflow: hidden;

				>p {
					font-size: 14px;
					color: #112950;
				}

				>div {
					margin: 10px 0;
					padding: 14px 20px;
					border-radius: 10px;
					font-size: 15px;
					color: #000;
					text-align: justify;
				}
			}

			::v-deep .el-image {
				width: 300px;
				height: 200px;
				padding: 10px;
				border-radius: 6px;
				box-shadow: 0 0 20px rgba(0, 0, 0, .1);
			}

			.left,
			.center {
				flex: 2;
				margin-right: 20px;

				.el-col {
					>div {
						background-color: #F6F7F9;
					}
				}
			}

			.center {
				flex: 1;

				.el-col {
					>div {
						word-break: break-all;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}

			.right {
				flex: 1;
				max-width: 320px;
				min-width: 300px;

				.el-col {
					>div {
						padding: 0;
					}
				}
			}
		}

		.participationLink {
			.row {
				display: flex;

				.col {
					flex: 1;
				}
			}

			.qrcode,
			.linkUrl {
				margin-bottom: 30px;

				h4 {
					font-size: 22px;
					margin-bottom: 10px;
					width: 300px;
					text-align: center;
					display: flex;
					justify-content: center;
					flex-direction: column;

					span {
						font-size: 12px;
						color: #999;
					}
				}

				img {
					display: block;
					width: 300px;
					height: 300px;
					border-radius: 20rpx;
					box-shadow: 0 0 20px rgba(0, 0, 0, .1);
					overflow: hidden;
					cursor: pointer;
				}

				span {
					font-size: 14px;
				}
			}

			.options {
				display: flex;
				justify-content: center;

				.el-button {
					width: 200px;
				}


			}
		}

		.postMeetingUpload {
			display: flex;
			flex-direction: row;
			justify-content: center;

			.personalSettlement,
			.supplierSettlement {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;

				.card {
					margin: 30px;
					width: 300px;
					height: 300px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					border: 2px solid #f2f2f2;
					border-radius: 15px;
					font-size: 20px;
					color: #999;
					transition: all .3s;

					i {
						font-size: 200px;
						color: #f2f2f2;
						transition: all .3s;
					}


					&:hover {
						color: #0065FF;

						i {
							color: #0065FF;
						}
					}
				}
			}

			// flex-wrap: wrap;

			/* .row {
				margin-bottom: 20px;
				width: 100%;
				padding: 20px 20px;
				border-radius: 20px;
				box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);

				p {
					font-size: 18px;
					color: #112950;

					.error {
						font-size: 14px;
						margin-left: 10px;
						color: darkred;
					}

					.el-tooltip {
						font-size: 14px;
						margin-left: 10px;
					}
				}

				.rowTit {
					display: flex;
					align-items: center;
					margin-bottom: 10px;

					p {
						flex: 1;
					}
				}
			}

			.fileList {
				.fileRow {
					display: flex;
					align-items: center;
					justify-content: space-between;
					transition: all .3s;
					margin-bottom: 10px;

					b {
						font-size: 14px;
						font-weight: normal;
						cursor: pointer;
						display: flex;
						align-items: center;

						i {
							font-size: 16px;
							margin-right: 4px;
						}

						.el-icon-picture {
							color: blueviolet;
						}

						.el-icon-files {
							color: darkorange;
						}
					}

					.del {
						display: none;
						cursor: pointer;

						i {
							font-size: 16px;
						}
					}

					.fileType {
						display: block;
						color: green;
					}

					&:last-child {
						margin-bottom: 0;
					}

					&:hover {
						background-color: #f5f5f5;

						.del {
							display: block;
							color: red;
						}

						.fileType {
							display: none;
						}
					}
				}
			} */

		}

		.conferenceReplay {
			.crBox {
				width: 100%;
				padding: 20px;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
				border-radius: 20px;
			}

			.el-row {
				padding: 10px 0;
				margin-bottom: 20px;
				display: flex;
				align-items: center;
			}

			.tabTitle {
				border-bottom: 2px solid #eee;
				width: 100%;

				* {
					font-size: 16px;
				}

				.el-col {
					text-align: center;
					border-right: 1px solid #ccc;

					&:last-child {
						border-right: none;
					}
				}
			}

			.tabContent {
				border-radius: 10px;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
				width: 100%;

				* {
					font-size: 15px;
				}

				.el-col {
					padding: 10px 20px;
					text-align: center;
				}

				.preTax {
					padding: 5px 20px;
					border-radius: 20px;
					background-color: #F1F6FC;
					box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
				}

				.afterTax {
					padding: 5px 20px;
					border-radius: 20px;
					background-color: #F1FCF5;
					box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
				}
			}

			.endNode {
				border-radius: 10px;
				width: 100%;
				margin: 0;

				* {
					font-size: 16px;
					color: #0068FF;
				}

				.el-col {
					padding: 10px 20px;
					text-align: center;
				}

				.preAmount {
					font-size: 20px;
				}

				.amount {
					font-size: 20px;
				}
			}
		}

		.livePlayback {
			width: 100%;
			padding: 20px;
			border-radius: 20px;
			box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);


		}

		.options {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-top: 20px;

			.el-button {
				// width: 280px;
				// height: 50px;
				margin: 0 10px;
				font-size: 16px;
			}

			.el-link {
				.el-link--inner {
					display: flex;
					align-items: center;
					font-size: 16px;
				}

				.el-icon-check {
					border-radius: 5px;
					border: 1px solid #67C23A;
					margin-right: 5px;
				}
			}
		}
	}

	.el-dropdown-menu.el-popper.menu {
		padding: 0;

		.el-dropdown-menu__item {
			margin: 10px;
			padding: 0;

			.el-button {
				width: 100%;
			}
		}
	}

	::v-deep .projectSchedule {
		.el-dialog {
			margin: 0 auto !important;
			overflow: hidden;
			max-height: 90vh;
			overflow-y: auto;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.el-dialog__body {
				padding: 0 30px;

				.item {
					display: flex;
					align-items: flex-start;
					border-bottom: 1px solid #eee;
					padding: 10px 0;

					.time,
					.status {
						flex: 1;
					}

					.time {
						height: 40px;
						align-items: center;
						margin-right: 20px;

						.el-date-editor.el-input {
							width: 100%;
							height: 100%;

							.el-input__inner {
								height: 100%;
							}
						}
					}

					.status {
						flex: 2;
						display: flex;
						flex-direction: column;

						.type {
							height: 40px;
							display: flex;
							align-items: center;
							margin-bottom: 10px;
						}
					}
				}
			}
		}


	}

	::v-deep .settleAccounts {

		.el-dialog__body {
			padding-bottom: 10px;
		}

		.tips {
			background-color: #f6cdbb;
			padding: 20px;
			border-radius: 10px;

			h3 {
				margin-bottom: 10px;
			}

			p {
				margin-bottom: 5px;

				b {
					color: red;
				}
			}
		}
	}

	::v-deep .agreementDialog {
		margin: 0 !important;
		max-height: 95vh;
		overflow: hidden;
		overflow-y: auto;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;

		.el-dialog__body {
			flex: 1;

			video {
				display: block;
				margin: auto;
				width: 100%;
				height: 100%;
				max-height: 600px;

			}
		}

		.pages {
			text-align: center;
		}

		.opts {
			margin-top: 10px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}
	}

	::v-deep .fileLog {
		margin: 0 !important;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 90vw;
		max-height: 90vh;
		overflow-y: auto;

		.pdgBox {
			.pages {
				text-align: center;
			}

			.opts {
				display: flex;
				align-items: center;
				justify-content: space-around;
			}
		}
	}
</style>