<template>
	<div id="notifications">
		<el-collapse v-model="activeNames">
			<el-collapse-item v-for="item in notificaList" :key="item.id" :name="item.id">
				<template slot="title" class="collapseTit">
					{{item.message}}
					<el-tag size="mini" v-if="item.isView == '0'" type="warning"> 未读 </el-tag>
				</template>
				<div class="time_opt">
					<span>{{$moment(item.createTime).format('lll')}}</span>
					<el-button size="mini" type="primary" plain v-if="item.isView == '0'"
						@click="showView(item)">查看</el-button>
					<el-button size="mini" plain v-else @click="showView(item)">查看</el-button>
				</div>
				<!-- {{item.type}} -->
			</el-collapse-item>
		</el-collapse>
		<el-pagination @current-change="handleCurrentChange" background :current-page.sync="currentPage"
			:page-size="pageSize" layout="total, prev, pager, next" :total="total">
		</el-pagination>
	</div>
</template>

<script>
	import {
		getNotice,
		checkNotice,
		getOfficialNotice,
		checkNotOfficial
	} from '@/request/api.js';
	export default {
		name: 'notifications',
		data() {
			return {
				notificaList: [],
				currentPage: 1,
				pageSize: 10,
				total: 0,
				activeNames: [],
				token: ''
			}
		},
		created() {
			this.token = JSON.parse(localStorage.getItem('token'));
			this.getNoticeFun();
		},
		mounted() {},
		methods: {
			// 获取消息列表
			getNoticeFun() {
				let data = {
					current: this.currentPage,
					size: this.pageSize,
				};
				if (this.token.type == 'ASSISTANT') {
					getNotice(data).then(res => {
						if (res.code == 200) {
							this.notificaList = res.data.records;
							this.activeNames = res.data.records.map(item => item.id)
							this.total = res.data.total;
						};
					});
				} else if (this.token.type == 'OFFICIAL') {
					getOfficialNotice(data).then(res => {
						if (res.code == 200) {
							this.notificaList = res.data.records;
							this.activeNames = res.data.records.map(item => item.id)
							this.total = res.data.total;
						};
					});
				}
			},

			handleCurrentChange(val) {
				this.currentPage = val;
				this.getNoticeFun();
			},

			showView(item) {
				console.log(JSON.parse(JSON.stringify(item)))
				localStorage.setItem('projectDetails', JSON.stringify({
					id: item.projectId
				}));
				if (this.token.type == 'ASSISTANT') {
					checkNotice({
						noticeId: item.id
					}).then(res => {
						if (res.code == 200) {
							this.getNoticeFun();
							if (item.type == "CLAIM" || item.type == "WAIT") {
								this.$router.push({
									name: 'meetdetails',
									query: {
										mId: item.meetingId,
										status: 'WAIT_CLAIM',
										now: 3
									}
								});
							} else if (item.type == "SUCCESS") {
								this.$router.push({
									name: 'meetdetails',
									query: {
										mId: item.meetingId,
										now: 3
									}
								});
							} else if (item.type == "OFFCIAL_ERROR" || item.type == "ERROR" || item.type ==
								"UPLOAD") {
								this.$router.push({
									name: 'uploadFile',
									query: {
										mId: item.meetingId
									}
								});
							};

						};
					});
				} else if (this.token.type == 'OFFICIAL') {
					checkNotOfficial({
						noticeId: item.id
					}).then(res => {
						if (res.code == 200) {
							clearTimeout(this.timer);
							this.timer = null;
							this.getNoticeFun();
							if (item.type == "UPLOAD" || item.type == "ERROR") {
								// officialMeetingDetail?meetingId=1830

								this.$router.push({
									name: 'officialMeetingDetail',
									query: {
										meetingId: item.meetingId,
									}
								});
							}
						}
					})
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	#notifications {
		padding: 20px;

		::v-deep .el-collapse {
			padding: 0 10px;

			.el-collapse-item__content {
				padding: 0 0 10px;
			}

			.el-collapse-item__header {
				font-size: 16px;
			}

			.el-tag {
				margin-left: 20px;
			}

			.time_opt {
				display: flex;
				align-items: center;
				justify-content: space-between;

				>span {
					font-size: 14px;
					color: #999;
				}

				.el-button {
					margin-left: 30px;
				}
			}
		}

		.el-pagination {
			margin: 20px;
			text-align: center;
		}
	}
</style>